/**
 * Datentyp für die Benutzerdaten
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2017-12-18
 * Revision: 2020-01-17
 *
 */



import { Project } from '../_models/project.model';



export class User {

    public id = -1; // ID des Benutzers

    public logdate = ''; // Datum Login im Format 'YYYY-MM-DD'

    public username = ''; // Benutzername
    public firstname = ''; // Vorname
    public lastname = ''; // Nachname
    public email = ''; // E-Mail
    public language = ''; // Sprache

    public token = ''; // Autentifizierungstoken

    public nav_today = 0; // User hat das aktuelle Datum in der Anzeige (1) oder nicht (alles andere)
    public nav_date = ''; // das in der View anzuzeigende Datum
    public timetracking = 0; // Zeiterfassung aktiv (1) oder nicht (alles andere)

    public projects: Project[] = null; // Alle Projekte des Users
    public currentProject: Project = new Project(); // Das aktuelle Projekt (ACHTUNG: Muss IMMER instanziiert sein!)
    public id_projects = -1; // ID des aktuelles Projekts (für den Austausch mit der DB)

    public companyname = ''; // Name der zugeordneten LORENZ-Tocher
    public companyadress = ''; // Formatierte (HTML/CSS) Adresse der Lorenz Tocher inkl. Kommunikationswegen (Tel, Fax etc.)

    public persnr = ''; // Personalnummer Lorenz

    public messeuser = 0; // Messeuser (1) oder nicht (alles andere)

    public client = 0; // 0=UNBEKANNT; 1=AppTitan; 2=Web
}
