/**
 * Zentrale Komponente für die Anzeige der Arbeitszeitdaten
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-02-01
 * Revision: 2019-10-28
 *
 * Section = Zeile (vor / aktuell / nach) - Anm.: Die "Section" rolliert, über den Index kann also nicht auf "aktuell/vor/nach" geschlossen werden!
 * Slide = Spalte (Tag / Monat / Jahr)
 *
 */

import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../environments/environment'
import { Display, DisplayViewDay, DisplayViewMonth, DisplayViewYear, HttpData, Result, ViewMatrix } from '../_models/_index'
import { DisplayService, HttpService, LanguageService, StorageService, ToolsService, UserService } from '../_services/_index'

declare var $: any
declare var scrollmasterInit: any
declare var scrollmasterDestroy: any
declare var scrollmasterSetAllowScrolling: any
declare var scrollmasterSilentMove: any

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HomeComponent implements OnChanges, OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

  @ViewChild('functions') public functions: ElementRef

  public display: Display = null // Die aktuelle Anzeige

  public scrollMasterView: ViewMatrix = null // Die Matrix mit den Anzeigedaten. Jede Zelle der Matrix enthält ein Display-Objekt.

  public debug = false // Wird aus dem ENVIRONMENT übernommen. Anzeige von DEBUGinformationen
  public debugStopwatch = 0 // Stoppuhr für das Request-Controlling

  public loading = false // Ladebildschirm anzeigen oder nicht
  public formattedDate = '' // Das formatierte Datum für die Anzeige
  public alert = '' // Container für Meldungen und Fehler

  public lastTag = '' // Tag, an dem auf die Monatsebene gewechselt wurde
  public lastMonat = '' // Monat, in dem auf die Jahresebene gewechselt wurde
  public lastRichtung = '' // Letzte Up-/Down Scrollrichtung vor dem Ebenenwechsel

  public isOnline = false // System ONLINE oder nicht

  public extCall = false // true, wenn Aufruf von außen
  public sessionStopped = false // Flag für den Session-Abbruch

  public timerMidnightRefresh: any = null // Timer (Timeout) für die automatische Aktualisierung um "00:00:00 Uhr"

  // LIFECYCLE -----------------------------------------------------------------------------------------------

  public constructor(
    public ngZone: NgZone,
    public router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    public displayService: DisplayService,
    public httpService: HttpService,
    public languageService: LanguageService,
    public storageService: StorageService,
    public toolsService: ToolsService,
    public userService: UserService) {

    this.toolsService.debugInfo('home', 'constructor')

    // Schnittstelle für "fullpage.js"
    window['scrollmaster'] = {
      zone: ngZone,
      wipe: (dateOrDirection: string, oldSection: number, oldSlide: number, newSection: number, newSlide: number) => this.wipe(dateOrDirection, oldSection, oldSlide, newSection, newSlide), component: this,
    }
  }

  public ngOnChanges() {

    this.toolsService.debugInfo('home', 'ngOnChanges', 'LIFECYCLE')

    this.display = this.displayService.getDisplay()
  }

  public ngOnInit() {

    this.toolsService.debugInfo('home', 'ngOnInit', 'LIFECYCLE')

    // Event "cancelSession" im displayService abonieren, um von außen abbrechbar zu sein
    this.displayService.cancelSession.subscribe(() => {
      this.extCall = true
      this.cancelSession()
    })

    // Event "refreshView" im displayService abonieren, um von außen aktualisierbar zu sein
    this.displayService.refreshView.subscribe(() => {
      this.extCall = true
      this.ngAfterViewInit()
      // window.location.reload();
    })

    // Event "midnightRefresh" aus dem "displayService" abonnieren, um von außen erreichbar zu sein
    this.displayService.midnightRefresh.subscribe(() => {
      this.midnightRefresh()
    })

    // Event "SystemOnline" aus dem "httpService" für die ONLINE/OFFLINE-Detection abonnieren
    this.httpService.SystemOnline.subscribe((systemOnline: boolean) => {
      this.isOnline = systemOnline
      this.toolsService.debugInfo('home', 'OnInit', 'SYSTEM', (this.isOnline ? 'ONLINE' : 'OFFLINE'))
      scrollmasterSetAllowScrolling(this.isOnline)
      if (this.isOnline === false) {
        this.switchToOffline()
      }
    })

    // Initialisierungen
    let user = this.userService.getUser()

    this.scrollMasterView = new ViewMatrix()
    this.display = this.displayService.setDisplay(new Display())
    this.debug = (environment.debug === true)

    // Navigationssdatum beim User ändern, wenn "Heute-Modus" aktiv oder "timetracking"
    if ((user.nav_today === 1) || (user.timetracking === 1)) {
      user.nav_date = this.toolsService.getDateString()
      user.nav_today = 1
      user = this.userService.setUser(user)
    }

    // Die Daten von "Heute" (Client-Zeit) vom Server abrufen und cachen
    this.getHeute()

    // Anzeigedatum prüfen und ggf. korrigieren
    if (user !== null) {
      if (this.storageService.get('Timetracker', null) !== null) {

        // Laufende Zeiterfassung => Anzeigedatum ändern auf Datum der letzten Zeiterfassung
        user.nav_date = this.storageService.get('TimetrackerDate', this.toolsService.getDateString())
        user.nav_today = 1
        user.timetracking = 1
        user = this.userService.setUser(user)

      } else if ((user.nav_today === 1) && (user.nav_date !== this.toolsService.getDateString())) {

        // User ist im "Heute-Modus" aber Datum ist nicht "Heute" => Wechsel auf "Heute"
        user.nav_date = this.toolsService.getDateString()
        user.nav_today = 1
        user = this.userService.setUser(user)

      } else if (this.toolsService.validDate(user.nav_date) === false) {

        // Ungültiges Datum beim User => ändern auf "Heute"
        user.nav_date = this.toolsService.getDateString()
        user.nav_today = 1
        user = this.userService.setUser(user)

      }
    } else {
      this.toolsService.debugInfo('home', 'ngOnInit', 'ERROR', '*** USER NULL ***')
    }

    // Timer einrichten
    this.midnightRefreshStart()
  }

  public ngAfterViewInit(): void {

    this.toolsService.debugInfo('home', 'ngAfterViewInit', 'LIFECYCLE')

    if (this.storageService.get('SESSION_STOPPED', 'FALSE') !== 'TRUE') {
      const user = this.userService.getUser()
      const slide = this.getSlide(user.nav_date) // Den aktuelle SLIDE auf Basis des aktuellen User-Datums ermitteln (ist hier garantiert sicher). SECTION ist beim Start immer 0.
      this.setView(user.nav_date, 0, slide, 'ngAfterViewInit') // Anzeige mit dem User-Datum in section/slide starten
      scrollmasterInit() // fullpage.js starten
      scrollmasterSilentMove(0, slide) // Bildschirm auf den richtigen SLIDE sliden.

      // Wenn der User "Timetracker" ist aber noch keine Zeiterfassung läuft => Wechslen auf "Heute".
      if ((user.timetracking === 1) && (this.storageService.get('Timetracker', null) === null)) {
        user.nav_date = this.toolsService.getDateString()
        user.nav_today = 1
        this.userService.setUser(user)
      }
    }
  }

  public ngAfterViewChecked(): void {

    // this.toolsService.debugInfo('home', 'ngAfterViewChecked', 'LIFECYCLE');

    if (this.storageService.get('SESSION_STOPPED', 'FALSE') === 'TRUE') {
      this.storageService.clear() // FEIERABEND
    }
    this.changeDetectorRef.detectChanges() // Wichtig, sonst gibt's einen "ExpressionChangedAfterItHasBeenCheckedError"
  }

  public ngOnDestroy() {

    this.toolsService.debugInfo('home', 'ngOnDestroy', 'LIFECYCLE')

    scrollmasterDestroy()
    window['scrollmaster'] = null

    // Timer aus
    this.midnightRefreshEnd()
    this.httpService.serverStatusCheckerEnd()
  }

  // METHODS ------------------------------------------------------------------------------------------------

  // Ermittelt das anzuzeigende Datum und leitet es an die Server-Kommunikationsmethode weiter.
  // SECTION/SLIDE sind der fullpage.js Screen, in dem der neue Bildschirm angezeigt werden soll.
  // Wenn "dateOrDirection" ein Datum ist, wird diese Datum gesetzt.
  // Wenn "dateOrDirection" 'eine Richtung ist, wird das anzuzeigende Datum aus den vorhandenen Informationen ermittelt.
  public wipe(dateOrDirection: string, oldSection: number, oldSlide: number, newSection: number, newSlide: number): void {

    this.toolsService.debugInfo('home', 'wipe', 'METHOD', 'dateOrDirection: ' + dateOrDirection)

    this.display = this.displayService.getDisplay()

    if ((typeof dateOrDirection !== 'undefined')) {

      if (this.display.date !== '') {

        const currentDatum = this.display.date // Das aktuelle Datum
        let neuesDatum: string // Das neue Datum

        if (('up|down|left|right').indexOf(dateOrDirection.toLowerCase()) === -1) {

          // Es wurde ein Datum geliefert

          neuesDatum = dateOrDirection

          if (this.toolsService.validDate(neuesDatum) === false) {
            neuesDatum = this.toolsService.getDateString() // Ist das gelieferte Datum ungültig, wird "Heute" genommen
          }

        } else {

          // Bei Rechts-Wipe den aktuellen Tag oder Monat für spätere Links-Wipes merken

          if (dateOrDirection === 'right') {
            if (oldSlide === 0) { // Tag -> Monat
              this.lastTag = currentDatum
              this.storageService.set('lastTag', currentDatum)
              if (environment.debug === true) {
                console.log('WechselTAG: ' + this.storageService.get('lastTag'))
              }
            }
            if (oldSlide === 1) { // Monat -> Tag
              this.lastMonat = currentDatum
              this.storageService.set('lastMonat', currentDatum)
              if (environment.debug === true) {
                console.log('WechselMONAT: ' + this.storageService.get('lastMonat'))
              }
            }
          }

          // Die letzte Up-Down-Wipe-Richtung für spätere Links-Wipes merken.

          if (('up|down').indexOf(dateOrDirection) !== -1) {
            this.lastRichtung = dateOrDirection
          }

          // Das neue Datum aus der Wipe-Richtung ableiten.
          // Die alte/bisherige/abzulösende Anzeige steht dabei immer in Section (Zeile/Row) 1!
          // Section & Slide enthalten bereits die Matrix-Koordinaten für die gewünschte Anzeige!

          switch (dateOrDirection) {

            case 'up': {
              neuesDatum = this.getCalcDate(currentDatum, -1)
              neuesDatum = neuesDatum.substr(0, currentDatum.length)
              break
            }

            case 'down': {
              neuesDatum = this.getCalcDate(currentDatum, + 1)
              neuesDatum = neuesDatum.substr(0, currentDatum.length)
              break
            }

            case 'left': {

              if (newSlide === 1) { // Monat <- Jahr

                this.lastMonat = this.storageService.get('lastMonat', null)
                if ((this.lastMonat !== null) && (this.lastMonat.substr(0, 4) === currentDatum)) {
                  neuesDatum = this.lastMonat
                } else {
                  neuesDatum = currentDatum + '-' + (this.lastRichtung === 'up' ? '99' : '00')
                }
                this.lastMonat = ''
                this.storageService.remove('lastMonat')

              } else if (newSlide === 0) { // Tag <- Monat

                this.lastTag = this.storageService.get('lastTag', null)
                if ((this.lastTag !== null) && (this.lastTag.substr(0, 7) === currentDatum)) {
                  neuesDatum = this.lastTag
                } else {
                  neuesDatum = currentDatum + '-' + (this.lastRichtung === 'up' ? '99' : '00')
                }
                this.lastTag = ''
                this.storageService.remove('lastTag')
              }

              break
            }

            case 'right': {
              neuesDatum = currentDatum.substr(0, currentDatum.length - 3)
              break
            }
          }
        }

        // AB HIER HABEN WIR DAS ZIELDATUM, DIE ZUKÜNFTIGE SEKTION UND DEN/DIE/DAS ZUKÜNFTIGEN SLIDE

        // LocalStorage korrigieren
        if (newSlide === 0) {
          this.storageService.remove('lastTag')
          this.storageService.remove('lastMonat')
        } else if (newSlide === 1) {
          this.storageService.remove('lastMonat')
        }

        // User aktualisieren
        const user = this.userService.getUser()
        user.nav_date = neuesDatum
        if (neuesDatum === this.toolsService.getDateString()) {
          user.nav_today = 1
        } else {
          user.nav_today = 0
        }
        this.userService.setUser(user, 'home.wipe')

        if (this.debug === true) { console.log('Wipe to ' + newSection + '/' + newSlide + '/' + neuesDatum) }

        this.setView(neuesDatum, newSection, newSlide, 'wipe') // Weiter zur Datenbeschaffung und -anzeige
      }
    }
  }

  // Ermittelt die Daten für das übergebene Datum (Tag, Monat oder Jahr) und schreibt sie in die Anzeigematrix (scrollMasterView) in section (Zeile/Row) / slide (Spalte).
  public setView(date: string, section: number, slide: number, caller: string): void {

    this.display = this.displayService.getDisplay()
    let user = this.userService.getUser()

    this.toolsService.debugInfo('home', 'setView', 'METHOD', 'User:' + user.id + ' / Projekt: ' + user.currentProject.id + ' / Date: ' + date + ' / Section: ' + section + ' / Slide: ' + slide + ' / Caller: ' + caller)

    if ((this.loading === false) || (this.storageService.get('', 'FALSE') !== 'TRUE')) {

      this.loading = true // Ladebildschirm an
      scrollmasterSetAllowScrolling(false) // Scrollen sperren, solange Daten abgerufen werden

      // Daten vom Server holen

      this.scrollMasterView.row[section].item[slide] = new Display() // Die bisherige Anzeige in Section/Slide löschen
      const httpData = new HttpData(user, 'display')
      httpData.data['date'] = date
      httpData.data['timetracking'] = (this.storageService.get('Timetracker', null) !== null) && (this.storageService.get('TimetrackerDate', '') === this.toolsService.getDateString()) ? 1 : 0
      this.httpService.getDataByPost(httpData, 'home.setView').subscribe(

        (data: Result) => {

          if (data.status === 'OK') {

            if (this.debug === true) {
              const now = new Date().getTime()
              console.log('Display Request END (' + (now - this.debugStopwatch) + ' ms)') // Ende der Zeitmessung für den Datenabruf
            }

            this.display = new Display()
            this.display.typ = (data.data as Display).typ
            this.display.date = (data.data as Display).date
            this.display.projectname = (data.data as Display).projectname
            this.display.id_user = (data.data as Display).id_user
            this.display.id_project = (data.data as Display).id_project

            // Das gelieferte Datum auch dem User zuweisen (Erster und Letzter Tag im Monat werden mit "00" bzw. "99" angefordert)
            user.nav_date = this.display.date
            if (user.nav_date === this.toolsService.getDateString()) {
              user.nav_today = 1
            } else {
              user.nav_today = 0
            }

            user = this.userService.setUser(user)

            // Übertragen der gelieferten Daten in die passende interne Struktur (Tag, Monat oder Jahr)

            if (this.display.typ === 'tag') {

              this.display.view = data.data.view as DisplayViewDay
              if (this.display.view.toString() === '') {
                this.display.view = new DisplayViewDay() // Tag hat keine Daten
              } else {
                this.display.view.secondsWork = this.toolsService.convertStringToInt(this.display.view.secondsWork)
                this.display.view.secondsBreak = this.toolsService.convertStringToInt(this.display.view.secondsBreak)
              }
              this.display.view.blocked = (this.display.view.blocked === null ? '' : this.display.view.blocked)

            } else if (this.display.typ === 'monat') {

              this.display.view = new DisplayViewMonth()
              this.display.view.checkOk = data.data.checkOk as DisplayViewMonth
              this.display.view.transfer_user = (data.data.transfer_user as DisplayViewMonth === null ? '' : data.data.transfer_user as DisplayViewMonth)
              this.display.view.transfer_lorenz = (data.data.transfer_lorenz as DisplayViewMonth === null ? '' : data.data.transfer_lorenz as DisplayViewMonth)
              this.display.view.checksum = (data.data.checksum as DisplayViewMonth === null ? '' : data.data.checksum as DisplayViewMonth)
              this.display.view.days = data.data.view as DisplayViewMonth

              // Die Daten tageweise prüfen, ob sie "OK" sind
              for (let day = 0; day < this.display.view.days.length; day++) {
                this.display.view.days[day].checkOk = true
                if (this.toolsService.isSpecialDay(this.display.view.days[day].state) === false) {
                  this.display.view.days[day].checkOk = this.displayService.checkBreakTime(this.display.view.days[day].break, this.display.view.days[day].work)
                  if (this.display.view.days[day].checkOk === true) {
                    this.display.view.days[day].checkOk = this.displayService.checkWorkTime(this.display.view.days[day].work, this.display.view.days[day].comment)
                  }
                }
              }

            } else if (this.display.typ === 'jahr') {

              this.display.view = new DisplayViewYear()
              this.display.view = data.data.view as DisplayViewYear
            }

            this.display = this.displayService.setDisplay(this.display)

            this.scrollMasterView.row[section].item[slide] = this.display // Den neuen Bildschirm in die Anzeigestruktur einfügen...

            if (this.extCall === true) {
              scrollmasterSilentMove(section, slide)
            }
            this.extCall = false
            this.loading = false

            scrollmasterSetAllowScrolling(this.isOnline) // Scrollen wieder freigeben (ONLINE);
          } else {

            // ERROR - Fehler beim Datenabruf (System ist aber Online!)
            this.cancelSession() // SESSION IST UNGÜLTIG -> ABBRUCH
            scrollmasterSetAllowScrolling(this.isOnline) // Scrollen wieder freigeben (ONLINE);
          }
        },

        (error: Result) => {

          // Display-Abruf nicht möglich => OFFLINE => Cache benutzen
          this.display = this.displayService.getTodayCache()
          this.display = this.displayService.setDisplay(this.display)
          this.scrollMasterView.row[section].item[slide] = this.display // Den neuen Bildschirm in die Anzeigestruktur einfügen...
          this.toolsService.debugInfo('home', 'setView', 'METHOD', 'USE TODAY CACHE')
          scrollmasterSetAllowScrolling(this.isOnline) // Scrollen wieder freigeben (ONLINE);
          this.loading = false
        },
      )

      if (environment.debug === true) {
        this.debugStopwatch = new Date().getTime()
        console.log('Display Request START') // Überwachung der Abrufzeit (Start)
      }
    }
  }

  // Session beenden
  private cancelSession() {
    this.alert = this.languageService.get('de', 'SESSION_CANCELED') // Meldungstext
    this.scrollMasterView = new ViewMatrix()
    this.display = new Display()
    this.displayService.setDisplay(this.display)

    // Timer stoppen
    this.midnightRefreshEnd()
    this.httpService.serverStatusCheckerEnd()

    // Timetracker stoppen
    const timertracker = this.storageService.get('Timetracker', null)
    if (timertracker !== null) {
      const user = this.userService.getUser()
      this.toolsService.debugInfo('home', 'cancelSession')
      clearTimeout(timertracker)
      this.storageService.remove('Timetracker')
      this.storageService.remove('TimetrackerDate')
    }

    this.storageService.clear() // Alles Daten im Speicher löschen
    this.storageService.set('SESSION_STOPPED', 'TRUE')
    this.sessionStopped = true

    this.loading = false // Ladebildschirm aus
  }

  // Den  SLIDE auf Basis eines Datums ermitteln.
  public getSlide(datum: string) {

    datum = datum.toString()

    let slide = -1

    if (datum.length === 4) {
      slide = 2
    } else if (datum.length === 7) {
      slide = 1
    } else {
      slide = 0
    }

    return slide
  }

  // Leitet nach Session-Abbruch -Screen weiter.
  public login() {
    this.router.navigate(['/login'], { replaceUrl: true, skipLocationChange: true })
  }

  // Holt die "HEUTE"-Daten (Client-Zeit) vom Server und legt sie für den Offline-Modus im LocalStorage ab.
  // Ist der Server nicht erreichbar, wird eine DEFAULT-Tag abgelegt.
  public getHeute() {

    const user = this.userService.getUser()

    const heute = this.toolsService.getDateString()

    // DEFAULT-Tag als Vorgabe
    let data = new Display()
    data.typ = 'tag'
    data.date = heute
    data.view = new DisplayViewDay()
    data.view.state = 'STOP'
    data.view.begin = '08:00'
    data.view.work = '08:30'
    data.view.end = '17:00'
    data.view.break = '00:30'
    data.view.comment = ''

    const httpData = new HttpData(user, 'display')
    httpData.data['date'] = heute
    httpData.data['timetracking'] = (this.storageService.get('Timetracker', null) !== null) && (this.storageService.get('TimetrackerDate', '') === this.toolsService.getDateString()) ? 1 : 0
    this.httpService.getDataByPost(httpData, 'home.getHeute').subscribe(
      (response: Result) => {
        if (response.status === 'OK') {
          data = response.data
        } else {
          if (environment.debug === true) { console.log('ERROR: Fehler in "home.getHeute" - ' + JSON.stringify(response.data), 'color: red') }
        }
      },
      (error: Result) => {
        if (environment.debug === true) { console.log('ERROR: Fehler in "home.getHeute" - ' + JSON.stringify(error.data), 'color: red') }
      },
      () => {
        this.displayService.setTodayCache(data)
        this.toolsService.debugInfo('home', 'getHeute', 'METHOD', 'CACHE REFRESHED')
      },
    )
  }

  // METHODEN FÜR DEN MIDNIGHT REFRESH ----------------------------------------------------------------------

  // https://stackoverflow.com/questions/10944396/how-to-calculate-ms-since-midnight-in-javascript
  public midnightRefreshStart() {

    this.midnightRefreshEnd()

    const now = new Date()
    const then = new Date()
    then.setHours(0, 0, 0, 0)
    then.setDate(then.getDate() + 1)
    const diff = then.getTime() - now.getTime() // Millisekunden
    this.timerMidnightRefresh = setTimeout(() => {
      this.midnightRefresh()
    }, diff)
    if (environment.debug === true) {
      const stunden = Math.floor(diff / 1000 / 60 / 60)
      const minuten = Math.floor((diff - (stunden * 1000 * 60 * 60)) / 1000 / 60)
      const sekunden = Math.floor((diff - (stunden * 1000 * 60 * 60) - (minuten * 1000 * 60)) / 1000)
      console.log('TIMER: Midnight Refresh START (' + this.toolsService.convertToHMS(stunden + ':' + minuten + ':' + sekunden) + ' - TimerID #' + this.timerMidnightRefresh + ')')
    }
  }

  public midnightRefreshEnd() {
    if (this.timerMidnightRefresh !== null) {
      if (environment.debug === true) { console.log('TIMER: Midnight Refresh END (' + this.toolsService.getTimeStringHMS() + ' - TimerID #' + this.timerMidnightRefresh + ')') }
      clearInterval(this.timerMidnightRefresh)
      this.timerMidnightRefresh = null
    }
  }

  public midnightRefresh() {
    if (environment.debug === true) { console.log('TIMER: Midnight Refresh TRIGGER (' + this.toolsService.getTimeStringHMS() + ' - TimerID #' + this.timerMidnightRefresh + ')') }
    const user = this.userService.getUser()
    if ((user !== null) && (user.currentProject !== null) && (user.currentProject.messeprojekt.toString() === '1')) {

      // MessePROJEKTE (nicht nur die User!) werden um 24:00 Uhr ausgeloggt.
      // Code s. auch "functions.component.logout"

      this.storageService.set('SESSION_STOPPED', 'TRUE')
      this.displayService.sendTimeStopper()
      this.displayService.sendRefreshView()
      this.httpService.serverStatusCheckerEnd()
      this.storageService.clear()
      if (this.debug) { console.log('FUNCTION: SCREEN REFRESH') }
      this.router.navigate(['/login'], { replaceUrl: true, skipLocationChange: true })

    }
    window.location.reload()
  }

  // METHODEN FÜR VOR- UND NACHTAG/MONAT/JAHR ---------------------------------------------------------------

  // Berechnet den/das Vor-/Nach- Tag/Monat/Jahr
  public getCalcDate(aktuellesDatum: string, diff: number) {

    const slide = this.getSlide(aktuellesDatum)

    let newDate: Date = null

    if (slide === 0) { // TAG
      const date = new Date(parseInt(aktuellesDatum.substr(0, 4), 10), parseInt(aktuellesDatum.substr(5, 2), 10) - 1, parseInt(aktuellesDatum.substr(8, 2), 10))
      date.setDate(date.getDate() + diff)
      newDate = date

    } else if (slide === 1) { // MONAT
      const date = new Date(parseInt(aktuellesDatum.substr(0, 4), 10), parseInt(aktuellesDatum.substr(5, 2), 10) - 1, 1)
      date.setMonth(date.getMonth() + diff, 1)
      newDate = date

    } else if (slide === 2) { // JAHR
      const date = new Date(parseInt(aktuellesDatum.substr(0, 4), 10), 1, 1)
      date.setFullYear(date.getFullYear() + diff, 1, 1)
      newDate = date
    }

    const result = this.toolsService.getDateString(newDate)

    return result
  }

  // METHODEN FÜR DEN OFFLINE-MODUS -------------------------------------------------------------------------

  // Im Offline-Modus zeigt die App nur "Heute"
  public switchToOffline() {
    const user = this.userService.getUser()
    if (user.nav_date !== this.toolsService.getDateString()) {
      user.nav_date = this.toolsService.getDateString()
      user.nav_today = 1
      this.userService.setUser(user)
      this.storageService.remove('lastTag')
      this.storageService.remove('lastMonat')
      this.router.navigate(['/'], { replaceUrl: true, skipLocationChange: true })
      this.toolsService.debugInfo('home', 'switchToOffline')
      this.displayService.sendRefreshView()
    }
  }
}
