<div id="home-year">

  <div class="col-12 my-3 text-center header" [ngClass]="{'text-muted': isInactive === true}">
    {{formattedDate}}
  </div>

  <div class="px-2">

    <table class="table table-bordered m-0 p-0 w-100">

      <thead>
        <tr [ngClass]="{'text-muted': isInactive === true}">
          <th>Monat</th>
          <th>Stunden</th>
          <th>K</th>
          <th>U</th>
          <th>SU</th>
          <!-- <th>KuG</th> -->
          <th>FT</th>
          <th>GZ</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor='let item of display.view.months' (click)="goToMonat(item.date)" [ngClass]="{'text-muted': isInactive === true}">

          <td *ngIf="item.state!='NEU'" style="width: 12%;">{{item.monat}}</td>
          <td *ngIf="item.state!='NEU'">{{item.work}}</td>
          <td *ngIf="item.state!='NEU'" style="width: 12%;">{{item.krank}}</td>
          <td *ngIf="item.state!='NEU'" style="width: 12%;">{{item.urlaub}}</td>
          <td *ngIf="item.state!='NEU'" style="width: 12%;">{{item.sonderurlaub}}</td>
          <!-- <td *ngIf="item.state!='NEU'" style="width: 12%;">{{item.kurzarbeitergeld}}</td> -->
          <td *ngIf="item.state!='NEU'" style="width: 12%;">{{item.feiertag}}</td>
          <td *ngIf="item.state!='NEU'" style="width: 12%;">{{item.gleitzeit}}</td>

          <td *ngIf="item.state=='NEU'" class="text-muted" style="width: 12%;">{{item.monat}}</td>
          <td *ngIf="item.state=='NEU'" class="text-muted">---:--</td>
          <td *ngIf="item.state=='NEU'" class="text-muted" style="width: 12%;">-</td>
          <td *ngIf="item.state=='NEU'" class="text-muted" style="width: 12%;">-</td>
          <!-- <td *ngIf="item.state=='NEU'" class="text-muted" style="width: 12%;">-</td> -->
          <td *ngIf="item.state=='NEU'" class="text-muted" style="width: 12%;">-</td>
          <td *ngIf="item.state=='NEU'" class="text-muted" style="width: 12%;">-</td>
          <td *ngIf="item.state=='NEU'" class="text-muted" style="width: 12%;">-</td>

        </tr>
      </tbody>

      <tfoot>
        <tr [ngClass]="{'text-muted': isInactive === true}">
          <th>Summen</th>
          <th>{{display.view.sums.work}}</th>
          <th>{{display.view.sums.krank}}</th>
          <th>{{display.view.sums.urlaub}}</th>
          <!-- <th>{{display.view.sums.kurzarbeitergeld}}</th> -->
          <th>{{display.view.sums.sonderurlaub}}</th>
          <th>{{display.view.sums.feiertag}}</th>
          <th>{{display.view.sums.gleitzeit}}</th>
        </tr>
      </tfoot>

    </table>

  </div>

</div>