<nav id="navbar" class="navbar custom-toggler bg-primary p-2" [ngClass]="{'offline': isOnline === false}">
  <div class="d-flex flex-row w-100">

    <!-- Hamburgermenue -->

    <div class="flex-grow-0 fix-width text-left">
      <button class="navbar-toggler collapsed p-0" type="button" data-toggle="collapse" data-target="#collapsingMenu" (click)="curtainToggle()">
        <span class="icon-bar top-bar"></span>
        <span class="icon-bar middle-bar"></span>
        <span class="icon-bar bottom-bar"></span>
      </button>
    </div>

    <!-- Titel -->

    <div class="flex-fill">
      <div class="text-center text-nowrap" [attr.title]="debug ? debuginfos : null" [ngClass]="{'text-muted': isBlocked === true}">{{ title }}</div>
    </div>

    <!-- Files Download Dialog Button -->

    <div class="flex-grow-0 fix-width text-right">
      <div *ngIf="filesIconVisible" class="filesIcon" (click)="isOnline && openFilesDialogModal()" title="Wichtige Dokumente">
        <fa-icon class="bumm" [ngClass]="{ 'blink': filesIconAnimated }" [icon]="faFileAlt"></fa-icon>
      </div>
    </div>

  </div>
</nav>

<!-- DAS MENUE ---------------------------------------------------------------------------------------------------------->

<div id="collapsingMenuWrapper">

  <div id="collapsingMenuBlocker">&nbsp;</div>

  <div id="collapsingMenu" class="collapse navbar-collapse">
    <ul>
      <li *ngIf="isDebug === true" class="link" style="color: red;" (click)="debug()">DEBUG</li>

      <li *ngIf="(isOnline === true) && (hasProject === true)" class="link" (click)="heute()">Heute</li>
      <li *ngIf="(isOnline === false) || (hasProject === false)" class="text-muted">Heute</li>

      <li *ngIf="(isOnline === true) && (hasProject === true) && (projektwahl === true)" class="link" (click)="projektwechsel()">Projekt wechseln</li>
      <li *ngIf="(isOnline === false) || (hasProject === false) || (projektwahl === false)"><span class="text-muted">Projekt wechseln</span></li>

      <li *ngIf="(isOnline === true) && (hasProject === true)" class="link" (click)="passwortaenderung()">Passwort ändern</li>
      <li *ngIf="(isOnline === false) || (hasProject === false)" class="text-muted">Passwort ändern</li>

      <li *ngIf="(isOnline === true) && (isMesseuser === true)" class="link"><a target="lorenz_leitfaeden_messejob" href="{{urlLeitfaedenMessejob}}">Leitfäden Messejob</a></li>
      <li *ngIf="isOnline === true" class="link"><a target="lorenz_hilfe" href="{{urlHilfeFAQ}}">Hilfe / FAQ</a></li>
      <li *ngIf="(isOnline === false)" class="text-muted">Hilfe / FAQ</li>

      <li *ngIf="isOnline === true" class="link" (click)="logout()">Abmelden</li>
      <li *ngIf="(isOnline === false)" class="text-muted">Abmelden</li>
    </ul>
  </div>

</div>
