/**
 * Datentyp für die HTTP-Antworten
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-02-21
 * Revision: 2020-01-17
 *
 */

export class Result {
    public status = '' // 'OK' oder 'ERROR'
    public data: any = null
}
