/**
 * Komponente für die Dokumentenanzeige
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2020-12-02
 *
 */

import {AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core'
import {PDFDocumentProxy} from 'ng2-pdf-viewer'
import {environment} from 'src/environments/environment'
import {FileDocument, HttpData} from '../_models/_index'
import {FilesService, HttpService, ToolsService, UserService} from '../_services/_index'
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'

@Component({
    selector: 'app-files-viewer',
    templateUrl: './files-viewer.component.html',
    styleUrls: ['./files-viewer.component.css']
})
export class FilesViewerComponent implements OnChanges, AfterViewInit {

    @ViewChild('filesViewerModal', {static: true}) public filesViewerModal: ElementRef

    @Input() public file: FileDocument

    public fileUrl = '' // Das anzuzeigende PDF

    public isMp4: boolean

    public page = 0 // Die aktuell sichtbare Seite des aktuellen PDFs
    public totalPages = 0 // Die Anzahl Seiten im aktuelle angezeigte PDF

    // Die Navigationspfeile
    public faChevronLeft = faChevronLeft
    public faChevronRight = faChevronRight

    @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;

    // LIFECYCLE -----------------------------------------------------------------------------------------------

    constructor(
        private filesService: FilesService,
        private httpService: HttpService,
        private toolsService: ToolsService,
        private userService: UserService,
    ) {
        this.toolsService.debugInfo('FilesViewerComponent', 'constructor', 'LIFECYCLE')
    }

    public ngOnChanges(): void {
        this.refreshFileUrl()
    }

    public ngAfterViewInit(): void {

        // Das Modal beim Service anmelden.
        this.filesService.registerModal('FilesViewer', this.filesViewerModal)
    }

    // PUBLIC METHODS ------------------------------------------------------------------------------------------

    // Triggert, wenn das PDF geladen wurde.
    public afterLoadComplete(pdfData: PDFDocumentProxy) {
        this.page = 1
        this.totalPages = pdfData.numPages
    }

    // Seite vorblättern
    public nextPage() {
        if (this.totalPages > this.page) {
            this.page++
        }
    }

    // Seite zurückblättern
    public prevPage() {
        if (this.totalPages > 1) {
            this.page--
        }
    }

    toggleVideo(event: any) {
        this.videoplayer.nativeElement.play();
    }

    // PRIVATE METHODS -----------------------------------------------------------------------------------------

    // Erzeugt den Download-Aufruf für ein PDF aufgrund der "this.file.id".
    private refreshFileUrl(): void {

        if (this.file) {
            const user = this.userService.getUser()

            // Daten für den Abruf zusammenstellen und kodieren.
            const httpData = new HttpData(user, 'file-download')
            httpData.data['file_id'] = this.file.id
            const queryData = this.httpService.prepareData(httpData)

            this.fileUrl = `${environment.urlRest}?data=${queryData}`

            this.isMp4 = this.file.filename.slice(-4).toLowerCase() === '.mp4'
        }
    }

}
