/**
 * Komponente für die Anzeige der Arbeitszeitdaten - View "Tag"
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-03-01
 * Revision: 2019-10-08
 *
 */



import { environment } from '../../environments/environment';

import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { DisplayService, HttpService, StorageService, ToolsService, UserService } from '../_services/_index';

import { Display, HttpData, Result } from '../_models/_index';

declare var $: any;
declare var scrollmasterMoveRight: any;



@Component({
  selector: 'app-home-day',
  templateUrl: 'home-day.component.html',
  styleUrls: ['home-day.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomeDayComponent implements OnChanges, OnInit, AfterViewInit {

  @Input() display: Display; // DER AKTUELLE TAG (erstversorgt von der Elternkomponente)
  @Input() section: number; // Die aktuelle Sektion

  public debug = environment.debug; // Anzeige von DEBUGinformationen
  public debuginfos1 = ''; // Wenn DEBUG, dann werden diese Infos angezeigt
  public debuginfos2 = '';

  public formattedDate = ''; // Formatierte Datumsanzeige für den Tag
  public transfer_lorenz = ''; // Formatiertes Datum; Tag, an dem dem die Monatsübersicht zuletzt zu Lorenz übertragen wurde
  public time_suffix_begin = ''; // Text, der an die Zeitangabe "BEGIN" angehängt wird
  public time_suffix_end = ''; // Text, der an die Zeitangabe "END" angehängt wird

  public isHeute = false; // True, wenn "Heute" angezeigt wird - wird 1x pro Minute geprüft
  public isSpecialDay = false; // True, denn der Tag NICHT für eine Zeiterfassung verwendet wird (also Status "Urlaub", "Krank" etc. hat)
  public isBlocked = false; // True, wenn der Tag blockiert ist, also nichts mehr geändert werden darf
  public isNeu = false; // True, denn der Tag "NEU" ist, also noch keine Daten für ihn erfasst wurden
  public isMesseprojekt = false; // True, wenn das aktuelle Projekt ein ein Messeprojekt ist

  public checkBreakTimeOk = false; // Flag für korrekte Pausenzeiten (d.h., sie entprechen den gesetzlichen und lorenzschen Vorgaben)
  public checkWorkTimeOk = false; // Flag für korrekte Arbeitszeiten (d.h., sie entprechen den gesetzlichen und lorenzschen Vorgaben)

  public editMode = false; // Editiermodus AN (TRUE) oder AUS (FALSE)
  public editField = ''; // Name des aktuell editierten Feldes
  public editBackupView: Display = null; // Sicherung des Display-Objekts zu Beginn einer Änderung

  public timerSendData: any = null; // Timer für die Sendung der Zeitdaten an den Server

  public commentHTML = ''; // HTML-Konvertierung des Kommentars



  // LIFECYCLE -----------------------------------------------------------------------------------------------



  public constructor(
    public router: Router,
    public displayService: DisplayService,
    public httpService: HttpService,
    public storageService: StorageService,
    public toolsService: ToolsService,
    public userService: UserService) { }



  public ngOnChanges(changes: SimpleChanges) {

    this.toolsService.debugInfo('home-day', 'ngOnChanges', 'LIFECYCLE');

    this.display = this.displayService.getDisplay();

    let user = this.userService.getUser();

    // Dummy-Anzeigen für Tage mit Status "NEU"
    if (this.display.view.state === 'NEU') {
      this.display.view.begin = '--:--';
      this.display.view.end = '--:--';
      this.display.view.work = '--:--';
      this.display.view.break = '--:--';
      this.display.view.comment = '';
      this.display = this.displayService.setDisplay(this.display);
    }

    // Benötigte Datumsangaben
    this.formattedDate = this.toolsService.formatDate(this.display.date);
    this.transfer_lorenz = this.toolsService.getDatumString(new Date(this.display.view.blocked));

    // Tagesflags
    this.isHeute = (this.display.date === this.toolsService.getDateString());
    this.isSpecialDay = ((this.display.view.state !== 'START') && (this.display.view.state !== 'STOP') && (this.display.view.state !== 'NEU'));
    this.isBlocked = ((user.currentProject.inaktiv === 1) || (this.display.view.blocked.toString() !== ''));
    this.isNeu = (this.display.view.state === 'NEU') && (this.editMode === false);
    this.isMesseprojekt = (user.currentProject.messeprojekt.toString() === '1');

    // Pause und Arbeitszeit prüfen (übergeben werden die Anzeigedaten!)
    this.checkBreakTimeOk = this.displayService.checkBreakTime(this.display.view.break, this.display.view.work);
    this.checkWorkTimeOk = this.displayService.checkWorkTime(this.display.view.work, this.display.view.comment);

    // Wenn der Server-Timer noch nicht läuft aber laufen sollte...
    if ((this.storageService.get('Timetracker', null) !== null) && (this.timerSendData === null)) {
      this.timerSendData = this.storageService.get('Timetracker', null);
      user.timetracking = 1;
      user = this.userService.setUser(user);
      this.refreshJob();
    }

    // Anzeige aktualisieren
    this.displayArbeitszeit();

    // Debuginfos aktualisieren
    this.setDebugInfo();

    // Fürs Debugging, sind Display und Cache gleich?
    if (this.debug === true) {
      const myDisplay = this.displayService.getDisplay();
      const myCache = this.displayService.getTodayCache();
      const gleich = (JSON.stringify(myDisplay) === JSON.stringify(myCache));
      this.toolsService.debugInfo('home', 'ngOnChanges', 'DEBUG', 'Display === Cache => ' + (gleich ? 'TRUE' : 'FALSE'));
    }
  }



  public ngOnInit() {

    this.toolsService.debugInfo('home-day', 'ngOnInit', 'LIFECYCLE');

    // Event "timeStarter" aus dem "displayService" abonnieren, um von außen erreichbar zu sein (Start-Funktion)
    this.displayService.timeStarter.subscribe(() => {
      this.zeiterfassungStart();
    });

    // Event "timeStopper" aus dem "displayService" abonnieren, um von außen erreichbar zu sein (Stopp-Funktion)
    this.displayService.timeStopper.subscribe(() => {
      this.zeiterfassungStop();
    });

    this.display = this.displayService.getDisplay();
  }



  public ngAfterViewInit(): void {

    this.toolsService.debugInfo('home-day', 'ngAfterViewInit', 'LIFECYCLE');

    const user = this.userService.getUser();
    const timetracktimer = this.storageService.get('Timetracker', null);

    // Der User "Timetracker" ist und noch kein TimetrackTimer läuft...
    if ((user.timetracking === 1) && (timetracktimer === null)) {
      this.zeiterfassungStart();
    }
  }



  // EDITMODE ------------------------------------------------------------------------------------------------



  // "editMode" aktivieren
  public edit() {

    // this.display = this.displayService.getDisplay();

    this.editMode = true;
    this.isNeu = false;
    this.editBackupView = Object.assign({}, this.display.view); // Aktuelle Inhalte sichern
    this.setDisplayTimeSuffix();

    // "NEU"er Tag
    if (this.display.view.state === 'NEU') {
      this.display.view.begin = '08:00';
      this.display.view.end = '17:00';
      this.display.view.break = '00:30';
      this.display.view.comment = '';

      // Abweichungen "Heute";
      if (this.isHeute === true) {
        this.display.view.end = this.toolsService.getTimeStringHM();
        this.display.view.break = '00:00';
        if (Date.parse('01/01/1970 ' + this.display.view.end) < Date.parse('01/01/1970 ' + this.display.view.begin)) {
          this.display.view.begin = this.display.view.end;
        }
      }
    }
    this.display.view.firstStart = this.display.view.begin;
    this.display.view.lastStop = this.display.view.end;
    this.display.view.secondsBreak = this.toolsService.convertTimeToSeconds(this.display.view.break);
    this.display.view.secondsWork = this.toolsService.convertTimeToSeconds(this.display.view.lastStop) - this.toolsService.convertTimeToSeconds(this.display.view.firstStart) - this.display.view.secondsBreak;
    this.display.view.work = this.toolsService.convertSecondsToHM(this.display.view.secondsWork);

    // Pause und Arbeitszeit prüfen (übergeben werden die Anzeigedaten!)
    this.checkBreakTimeOk = this.displayService.checkBreakTime(this.display.view.break, this.display.view.work);
    this.checkWorkTimeOk = this.displayService.checkWorkTime(this.display.view.work, this.display.view.comment);

    this.display = this.displayService.setDisplay(this.display);
  }



  // "editMode" beenden; neue Werte prüfen und speichern
  public save() {

    this.display = this.displayService.getDisplay();

    // Das Kommentarfeld retten
    if ((this.display.view.state === 'START') || (this.display.view.state === 'STOP') || (this.display.view.state === 'NEU')) {
      this.display.view.comment = $('#comment-' + this.display.date).val();
    }

    this.editMode = false;
    this.setDisplayTimeSuffix();

    // Alle Zeiten ins Format HH:MM bringen
    let begin = this.toolsService.convertToHM(this.display.view.begin);
    let end = this.toolsService.convertToHM(this.display.view.end);
    const pause = this.toolsService.convertToHM(this.display.view.break);

    // Ggf. START und END tauschen
    if (end < begin) {
      const swap = end;
      end = begin;
      begin = swap;
    }

    // Die neue Arbeitszeit berechnen und prüfen
    const breakNeu = this.toolsService.convertTimeToSeconds(pause);
    const workNeu = this.toolsService.convertTimeToSeconds(end) - this.toolsService.convertTimeToSeconds(begin) - breakNeu;
    if (workNeu < 0) {

      // Fehler in den Eingabedaten - Abbruch

      this.display.view = this.editBackupView;
      this.display = this.displayService.setDisplay(this.display);
      alert('Eine Arbeitszeit von weniger als 0h kann nicht erfasst werden.');

    } else {

      // Alles i.O - Speichern

      if (this.display.view.state === 'NEU') {
        this.display.view.state = 'STOP';
      }
      this.isNeu = (this.display.view.state === 'NEU');

      this.display.view.firstStart = begin;
      this.display.view.lastStop = end;
      this.display.view.secondsWork = workNeu;
      this.display.view.secondsBreak = breakNeu;

      this.display.view.begin = begin;
      this.display.view.end = end;
      this.display.view.break = this.toolsService.convertSecondsToHM(this.display.view.secondsBreak);
      this.display.view.work = this.toolsService.convertSecondsToHM(this.display.view.secondsWork);

      this.display = this.displayService.setDisplay(this.display);

      // Die Tagesdaten an den Server übertragen
      const user = this.userService.getUser();
      const httpData = new HttpData(user, 'time');
      httpData.data['display'] = this.display;
      httpData.data['debug'] = 'home-day.save';
      this.httpService.getDataByPost(httpData, 'home-day.save').subscribe();

      // Pause und Arbeitszeit auf Gültigkeit prüfen
      this.checkBreakTimeOk = this.displayService.checkBreakTime(this.display.view.break, this.display.view.work);
      this.checkWorkTimeOk = this.displayService.checkWorkTime(this.display.view.work, this.display.view.comment);
    }

    // Abschluss
    this.editMode = false;
    this.editBackupView = null;
    $('input').blur();
  }



  // "editMode" abbrechen und vorherige Werte wiederherstellen
  public cancel() {

    this.display = this.displayService.getDisplay();

    this.editMode = false;
    this.setDisplayTimeSuffix();
    this.display.view = Object.assign({}, this.editBackupView);
    this.display = this.displayService.setDisplay(this.display);
    this.editBackupView = null;
    this.isNeu = (this.display.view.state === 'NEU');
    this.isSpecialDay = ((this.display.view.state !== 'START') && (this.display.view.state !== 'STOP') && (this.display.view.state !== 'NEU'));
    $('input').blur();
  }



  // Prüft die Texteingabe in einen Zeitfeld auf Format "HHMM" oder "HH:MM" und färbt das entsprechende Eingabefeld
  public checkTimeInput(event: any) {

    this.display = this.displayService.getDisplay();

    const element = event.target;
    const field = element.id.split('-')[0];
    let value = element.value;

    if (typeof value === 'undefined') {
      switch (field) {
        case 'begin':
          value = this.display.view.begin;
          break;
        case 'end':
          value = this.display.view.end;
          break;
        case 'break':
          value = this.display.view.break;
      }
    }

    // Prüfung der Eingabe.
    // - Wird ein "" geliefert wird die Zeit "00:00" angenommen.
    // - Wird ein ":" geliefert wird die Zeit "00:00" angenommen.
    // - Ist ein ":" enthalten, und ist ":" das letzte Zeichen, wird "00" angehängt.
    // - Ist ein ":" enthalten, wird die Zeit in das Format "HH:MM" umgewandelt und geprüft.
    // - Ist kein ":" enthalten, wird die Zeit geprüft, die sich ergibt, wenn der Prüfwert von Links auf 4 Zeichen erweitert und in der Mitte ein ":" eingefügt wird.

    let checkMe = value.trim();

    if ((checkMe === '') || (checkMe === ':')) {
      checkMe = '00:00';
    }

    if (checkMe.indexOf(':') === (checkMe.length - 1)) {
      checkMe = checkMe + '00';
    }

    if (checkMe.indexOf(':') !== -1) {
      checkMe = this.toolsService.checkTimeFormat(checkMe);
    }

    if ((checkMe.indexOf(':') < 0) && (checkMe.length <= 4)) {
      checkMe = this.toolsService.padLeft(checkMe, '0', 4);
      checkMe = checkMe.substr(0, 2) + ':' + checkMe.substr(2, 2);
    }

    const valid = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(checkMe);

    if (valid) {
      element.classList.remove('invalid');
    } else {
      element.classList.add('invalid');
    }

    this.toolsService.debugInfo('home-day, checkTimeInput', 'METHOD', 'Eingabe: ' + value + ' - Prüfwert: ' + checkMe + ' - Valid: ' + (valid ? 'OK' : 'Fehler'));
  }



  // Prüft die Texteingabe in einem Zeitfeld vor dem Speichern
  public checkTimeChange(event: any) {

    this.display = this.displayService.getDisplay();

    const element = event.target;
    const field_id = element.id;
    const field = field_id.split('-')[0];
    let value = element.value;

    // Eingabe korrigieren (Infos s. "checkTimeInput")

    value = value.trim();

    if ((value === '') || (value === ':')) {
      value = '00:00';
    }

    if (value.indexOf(':') === (value.length - 1)) {
      value = value + '00';
    }

    if (value.indexOf(':') !== -1) {
      value = this.toolsService.checkTimeFormat(value);
    }

    if ((value.indexOf(':') < 0) && (value.length <= 4)) {
      value = this.toolsService.padLeft(value, '0', 4);
      value = value.substr(0, 2) + ':' + value.substr(2, 2);
    }

    const valid = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);

    // Ist der eingegebene Wert keine gültige Zeitangabe, wird der bisherige Wert wieder hergestellt.

    if ((!valid) || (value === '')) {
      switch (field) {

        case 'begin':
          value = this.display.view.begin;
          break;
        case 'end':
          value = this.display.view.end;
          break;
        case 'break':
          value = this.display.view.break;
          break;
      }
    }

    value = this.toolsService.checkTimeFormat(value);

    switch (field) {
      case 'begin':
        this.display.view.begin = this.toolsService.convertToHM(value);
        this.display = this.displayService.setDisplay(this.display);
        $('#' + field_id).val(value);
        break;
      case 'end':
        this.display.view.end = this.toolsService.convertToHM(value);
        this.display = this.displayService.setDisplay(this.display);
        $('#' + field_id).val(value);
        break;
      case 'break':
        this.display.view.break = this.toolsService.convertToHM(value);
        this.display = this.displayService.setDisplay(this.display);
        $('#' + field_id).val(value);
        break;
    }

    element.classList.remove('invalid'); // Der Wert MUSS an dieser Stelle IMMER "valid" sein!

    // Pause und Arbeitszeit auf Gültigkeit prüfen
    this.display = this.displayService.getDisplay();
    this.checkBreakTimeOk = this.displayService.checkBreakTime(this.display.view.break, this.display.view.work);
    this.checkWorkTimeOk = this.displayService.checkWorkTime(this.display.view.work, this.display.view.comment);
  }



  // Ändert den Tagesstatus
  public changeDayState(state: string) {

    this.display = this.displayService.getDisplay();

    this.display.view.state = state;
    this.display = this.displayService.setDisplay(this.display);
    this.isSpecialDay = ((this.display.view.state !== 'START') && (this.display.view.state !== 'STOP') && (this.display.view.state !== 'NEU'));
  }



  // ZEITERFASSUNG -------------------------------------------------------------------------------------------



  // Starten der Zeiterfassung (geht nur "Heute" lt. Client Zeit)
  public zeiterfassungStart() {

    const jetzt = this.toolsService.getTimeStringHM();

    let todayCache = this.displayService.getTodayCache();

    // Zeitsendetimer löschen
    this.stopServerTimer();

    if (todayCache.view.firstStart === '') {

      // Erster Start des Tages ist noch nicht definiert

      todayCache.view.firstStart = jetzt;
      todayCache.view.lastStop = jetzt;
      todayCache.view.secondsBreak = 0;

    } else if ((Date.parse('01/01/1970 ' + todayCache.view.firstStart) > Date.parse('01/01/1970 ' + jetzt))) {

      // Arbeitsbeginn (und damit auch Arbeitsende) liegen hinter "jetzt"

      todayCache.view.firstStart = jetzt;
      todayCache.view.lastStop = jetzt;
      todayCache.view.secondsBreak = 0;

    }

    // Die Pause um die verstichene Zeit seit dem letzten Stopp verlängern
    todayCache.view.secondsBreak += this.toolsService.convertTimeToSeconds(jetzt) - this.toolsService.convertTimeToSeconds(todayCache.view.lastStop);

    // "lastStop" ab hier immer "Jetzt" setzten
    todayCache.view.lastStop = jetzt;

    // Wenn "Arbeitsbeginn" > "Arbeitsende" ist => Beide gleich setzten
    if ((Date.parse('01/01/1970 ' + todayCache.view.firstStart) > Date.parse('01/01/1970 ' + todayCache.view.lastStop))) {
      todayCache.view.lastStop = todayCache.view.firstStart;
    }

    // Wenn die Pause (nach den Korrekturen oben) "<0" ist ODER zu einer Arbeitszeit "<0" führt, die Pause auf das mögliche Maximum setzten
    if ((todayCache.view.secondsBreak < 0) || (this.toolsService.convertTimeToSeconds(todayCache.view.lastStop) - this.toolsService.convertTimeToSeconds(todayCache.view.firstStart)) < todayCache.view.secondsBreak) {
      todayCache.view.secondsBreak = this.toolsService.convertTimeToSeconds(todayCache.view.lastStop) - this.toolsService.convertTimeToSeconds(todayCache.view.firstStart);
    }

    todayCache.view.secondsWork = this.toolsService.convertTimeToSeconds(todayCache.view.lastStop) - this.toolsService.convertTimeToSeconds(todayCache.view.firstStart) - todayCache.view.secondsBreak;

    todayCache.view.state = 'START';

    // AB HIER SIND ALLES WERTE KORRIGIERT UND RICHTIG

    this.isNeu = false;

    todayCache.view.begin = todayCache.view.firstStart;
    todayCache.view.end = todayCache.view.lastStop;
    todayCache.view.break = this.toolsService.convertSecondsToHM(todayCache.view.secondsBreak);
    todayCache.view.work = this.toolsService.convertSecondsToHM(todayCache.view.secondsWork);

    todayCache = this.displayService.setTodayCache(todayCache);

    this.displayArbeitszeit(); // Hier werden die Anzeige-Properties gesetzte

    // Die Tagesdaten an den Server senden
    const httpData = new HttpData(this.userService.getUser(), 'time');
    httpData.data['display'] = todayCache;
    httpData.data['debug'] = 'homeDay.zeiterfassungStart';
    this.httpService.getDataByPost(httpData, 'homeDay.zeiterfassungStart').subscribe();
    this.storageService.set('TimetrackerDate', this.toolsService.getDateString());

    // User aktualisieren
    const user = this.userService.getUser();
    user.timetracking = 1;
    this.userService.setUser(user);

    // Timer für die Sendung an den Server
    this.startServerTimer();

    // Debuginfos aktualisieren
    this.setDebugInfo();

    // Display aktualisieren
    this.display = this.displayService.getDisplay();
  }



  // Stoppen der Zeiterfassung ist "autostop === true" wir Status "AUTOSTOP" gesetzt, sonst "STOP"
  public zeiterfassungStop(autostop: boolean = false) {

    const jetzt = this.toolsService.getTimeStringHM();

    const user = this.userService.getUser();
    user.timetracking = 0;
    this.userService.setUser(user);

    let todayCache = this.displayService.getTodayCache();

    // Zeitsendetimer löschen
    this.stopServerTimer();

    if (autostop === false) {

      // NORMALE ZEITAKTUALISIERUNG

      if (todayCache.view.state === 'START') {
        todayCache.view.lastStop = jetzt;
        todayCache.view.end = jetzt;
        todayCache.view.state = 'STOP';
        this.displayService.setTodayCache(todayCache);
      }

      // Pause und Arbeitszeit auf Gültigkeit prüfen, wenn wir auf "Heute" sind
      this.display = this.displayService.getDisplay();
      if ((this.display.date === this.toolsService.getDateString()) && (this.display.typ === 'tag')) {
        this.checkBreakTimeOk = this.displayService.checkBreakTime(this.display.view.break, this.display.view.work);
        this.checkWorkTimeOk = this.displayService.checkWorkTime(this.display.view.work, this.display.view.comment);
      }

      this.displayArbeitszeit();

      // Die Tagesdaten an den Server senden
      const httpData = new HttpData(user, 'time');
      httpData.data['display'] = this.displayService.getTodayCache();
      httpData.data['debug'] = 'homeDay.zeiterfassungStop';
      this.httpService.getDataByPost(httpData, 'home-day.zeiterfassungStop').subscribe();

    } else {

      // MIDNIGHT AUTOSTOP

      todayCache.view.state = 'STOP';

      todayCache.view.lastStop = '23:59';
      todayCache.view.end = todayCache.view.lastStop;

      if (todayCache.view.comment.substr(0, 8) !== 'AUTOSTOP') {
        todayCache.view.comment = 'AUTOSTOP' + (todayCache.view.comment === '' ? '' : ' - ' + todayCache.view.comment);
      }

      todayCache = this.displayService.setTodayCache(todayCache);

      const tempUser = this.userService.getUser();
      tempUser.nav_date = todayCache.date;
      tempUser.nav_today = 0;
      this.userService.setUser(tempUser);

      this.displayService.sendRefreshView();
    }

    // Debuginfos aktualisieren
    this.setDebugInfo();
  }



  // Anzeige der Zeitdaten (nur Anzeige, nicht Speichern!) - Arbeitet nur mit "Heute" !!!
  public displayArbeitszeit() {

    if (this.debug) {
      console.log('METHOD: homeDay.displayArbeitszeit ' + this.toolsService.getTimeStringHMS());
    }

    const todayDisplay = this.displayService.getTodayCache();

    if (todayDisplay.view.state !== 'NEU') {
      todayDisplay.view.begin = todayDisplay.view.firstStart;

      // Wenn die Zeiterfassung läuft, wird die Endzeit ausgeblendet
      if (todayDisplay.view.state === 'START') {
        todayDisplay.view.end = '--:--';
      } else {
        todayDisplay.view.end = todayDisplay.view.lastStop;
      }
      todayDisplay.view.break = this.toolsService.convertSecondsToHM(todayDisplay.view.secondsBreak);
      todayDisplay.view.secondsWork = this.toolsService.convertTimeToSeconds(todayDisplay.view.lastStop) - this.toolsService.convertTimeToSeconds(todayDisplay.view.firstStart) - todayDisplay.view.secondsBreak;
      todayDisplay.view.work = this.toolsService.convertSecondsToHM(todayDisplay.view.secondsWork);
    }

    // Cache aktualisieren
    this.displayService.setTodayCache(todayDisplay);

    // "HEUTE" auf das angezeigte DISPLAY-Objekt übertragen, wenn wir in der Tagessicht sind
    const tempDisplay = this.displayService.getDisplay();
    // this.isHeute = (tempDisplay.date === this.toolsService.getDateString());

    this.setDisplayTimeSuffix();

    if (this.debug) { console.log('CACHE REFRESH ' + this.toolsService.getTimeStringHMS()); }

    // Debuginfos aktualisieren
    this.setDebugInfo();
  }



  // Job, der 1x pro Minute (Sekunde "0" auf dem Client) die aktuellen Zeitdaten an den Server sendet und ggf. den Tag beendet
  public refreshJob() {

    if (this.debug) {
      console.log('METHOD: homeDay.refreshJob ' + this.toolsService.getTimeStringHMS());
    }

    // Timer Zeiterfassung stoppen aber vorher merken, welchen Tag wir tracken!
    const timetrackerDate = this.storageService.get('TimetrackerDate', '');
    this.stopServerTimer();

    // Tageswechsel?
    if (timetrackerDate !== this.toolsService.getDateString()) {

      // Ja
      this.zeiterfassungStop(true); // AUTOSTOP

    } else {

      // Nein

      let todayDisplay = this.displayService.getTodayCache();

      todayDisplay.view.lastStop = this.toolsService.getTimeStringHM(); // Der letzte Stopp ist immer "Jetzt" (für den Fall, dass das System abstürzt)
      todayDisplay = this.displayService.setTodayCache(todayDisplay);

      this.displayArbeitszeit(); // Anzeige aktualisieren und ggf. auch den Cache

      // Die Tagesdaten an den Server senden
      const httpData = new HttpData(this.userService.getUser(), 'time');
      httpData.data['display'] = this.displayService.getTodayCache();
      httpData.data['debug'] = 'homeDay.refreshJob';
      this.httpService.getDataByPost(httpData, 'homeDay.refreshJob').subscribe();
      this.storageService.set('TimetrackerDate', this.toolsService.getDateString())

      // Timer für die Datensendung an den Server neu starten
      this.startServerTimer();
    }

    // Debuginfos aktualisieren
    this.setDebugInfo();

    // Display aktualisieren
    this.display = this.displayService.getDisplay();
  }



  // HELPER --------------------------------------------------------------------------------------------------



  // Setzt den Focus auf das übergebene Eingabefeld
  public jumpToField(field: string) {
    this.display = this.displayService.getDisplay();
    $('#' + field + '-' + this.display.date).focus();
  }



  // Wechselt auf die Monatssicht
  public jump2todayMonth() {
    const user = this.userService.getUser();
    user.nav_date = this.toolsService.getDateString(new Date(), 'YYYY-MM');
    user.nav_today = 0;
    this.userService.setUser(user);
    this.storageService.remove('lastTag');
    this.storageService.remove('lastMonat');
    this.router.navigate(['/'], { replaceUrl: true, skipLocationChange: true });
    if (this.debug) { console.log('FUNCTION: SCREEN REFRESH'); }
    this.displayService.sendRefreshView();
  }



  // Aktualisiert die Debug-Infos
  public setDebugInfo() {
    if ((this.debug) && (this.storageService.get('SESSION_STOPPED', false) === false)) {
      this.display = this.displayService.getDisplay();

      this.debuginfos1 = 'Datum: ' + this.display.date.toString();
      this.debuginfos1 += (this.isHeute ? ' (HEUTE)' : '');

      this.debuginfos1 += ' / Freigegeben: ' + this.display.view.blocked;

      this.debuginfos1 += (this.isSpecialDay ? ' (SPECIALDAY)' : '');
      this.debuginfos1 += (this.editMode ? ' / EDITMODE' : '');
      this.debuginfos1 += (this.isBlocked ? ' / BLOCKED' : '');

      this.debuginfos2 = 'Tagesstatus: ' + this.display.view.state;
      this.debuginfos2 += ' / First Start: ' + this.display.view.firstStart;
      this.debuginfos2 += ' / Last Stop: ' + this.display.view.lastStop;
      this.debuginfos2 += ' / Seconds Work: ' + this.display.view.secondsWork;
      this.debuginfos2 += ' / Seconds Break: ' + this.display.view.secondsBreak;
    }
  }



  // Setzte den Zusatz für die Zeitangaben "BEGIN" und "END"
  public setDisplayTimeSuffix() {

    if ((this.editMode === false) && (this.display.view.state !== 'NEU')) {
      this.time_suffix_begin = ' Uhr';
    } else {
      this.time_suffix_begin = '';
    }

    if ((this.editMode === false) && ((this.display.view.state === 'STOP'))) {
      this.time_suffix_end = ' Uhr';
    } else {
      this.time_suffix_end = '';
    }
  }



  // Timer für die Datensendung an den Server - der Timer "zündet" immer zur Sekunden "0" (auf dem Client)!
  public startServerTimer() {

    this.stopServerTimer();

    const intervall = (60 - (new Date()).getSeconds()); // Sekunden
    this.timerSendData = setTimeout(() => {
      this.timerSendData = this.storageService.get('Timetracker', null);
      const tempUser = this.userService.getUser();
      if (environment.debug === true) { console.log('TIMER: Zeiterfassung TRIGGER (' + this.toolsService.getTimeStringHMS() + ' - User/Projekt #' + tempUser.id + '/#' + tempUser.currentProject.id + ' - TimerID #' + this.timerSendData + ')'); }
      this.refreshJob();
    }, intervall * 1000); // Millisekunden
    this.storageService.set('Timetracker', this.timerSendData.toString());
    const user = this.userService.getUser();
    if (environment.debug === true) { console.log('TIMER: Zeiterfassung START (Intervall ' + intervall + ' Sekunden - User/Projekt #' + user.id + '/#' + user.currentProject.id + ' - TimerID #' + this.timerSendData + ')'); }
  }



  // Timer für die Datensendung an den Server stoppen/löschen.
  public stopServerTimer() {
    this.timerSendData = this.storageService.get('Timetracker', null);
    if (this.timerSendData !== null) {
      const user = this.userService.getUser();
      if (environment.debug === true) { console.log('TIMER: Zeiterfassung END (' + this.toolsService.getTimeStringHMS() + ' - User/Projekt #' + user.id + '/#' + user.currentProject.id + ' - TimerID #' + this.timerSendData + ')'); }
      clearTimeout(this.timerSendData);
      this.storageService.remove('Timetracker');
      this.storageService.remove('TimetrackerDate');
    }
  }



  // Prüft, ob der Timer für den "RefreshJob" richtig läuft.
  public RefreshJobTimerChecker() {
    this.timerSendData = this.storageService.get('Timetracker', null);
    if (this.timerSendData !== null) {
      const user = this.userService.getUser();
      if (environment.debug === true) { } console.log('TIMER: Zeiterfassung END (' + this.toolsService.getTimeStringHMS() + ' - User/Projekt #' + user.id + '/#' + user.currentProject.id + ' - TimerID #' + this.timerSendData + ')');
    }
    clearTimeout(this.timerSendData);
    this.storageService.remove('Timetracker');
    this.storageService.remove('TimetrackerDate');
  }
}
