/**
 * Komponente für den Login-Dialog
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2017-12-01
 * Revision: 2019-10-29
 *
 */

import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../environments/environment'
import { HttpData, Project, Result, User } from '../_models/_index'
import { HttpService, LanguageService, FilesService, StorageService, ToolsService, UserService } from '../_services/_index'

declare var $: any

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css'],
})
export class LoginComponent implements OnInit {

    public version = '' // Text für die Versionsanzeige
    public username = '' // Benutzername
    public password = '' // Passwort zum Benutzernamen
    public stayLogged = false // Angemeldet bleiben oder nicht - 2018-07-31 - Deaktiviert, s. Mails
    public alert = '' // Container für Rückmeldungen
    public waitForServer = false // Flag, wenn auf ein Server-Antwort gewartet werden soll

    // LIFECYCLE -----------------------------------------------------------------------------------------------

    public constructor(
        private router: Router,
        private httpService: HttpService,
        private languageService: LanguageService,
        private filesService: FilesService,
        private storageService: StorageService,
        private toolsService: ToolsService,
        private userService: UserService) { }

    public ngOnInit() {

        this.toolsService.debugInfo('login', 'ngOnInit', 'LIFECYCLE')

        this.clearCurrentUser()
        this.version = environment.version
    }

    // LOGIN --------------------------------------------------------------------------------------------------

    public login() {
        if (this.waitForServer === false) {
            this.waitForServer = true
            this.alert = ''
            if ((this.username !== '') || (this.password !== '')) {
                if (this.username === '') {
                    this.alert = this.languageService.get('de', 'USERNAME_MISSING')
                    this.waitForServer = false
                } else if (this.password === '') {
                    this.alert = this.languageService.get('de', 'PASSWORD_MISSING')
                    this.waitForServer = false
                } else {
                    const httpData = new HttpData(new User(), 'login')
                    httpData.data['username'] = this.username
                    httpData.data['password'] = this.password
                    this.httpService.getDataByPost(httpData, 'login.login').subscribe(

                        (result: Result) => {
                            if (result.status === 'OK') {

                                const user = result.data as User

                                user.id = parseInt(user.id.toString(), 10)
                                user.logdate = this.toolsService.getDateString()
                                user.nav_today = (user.nav_today.toString() === '1' ? 1 : 0)
                                user.timetracking = (user.timetracking.toString() === '1' ? 1 : 0)
                                user.messeuser = (user.messeuser.toString() === '1' ? 1 : 0)

                                // Navigationsdatum beim User ändern, wenn "Heute-Modus" aktiv oder "timetracking"
                                if ((user.nav_today === 1) || (user.timetracking === 1)) {
                                    user.nav_date = this.toolsService.getDateString()
                                }

                                user.client = this.storageService.get('ClientType', 0) // 1 => appTitan; alles andere => Web

                                this.storageService.set('Salt', JSON.stringify(user.token))

                                this.toolsService.debugInfo('login', 'login', 'SYSTEM', 'NEW TOKEN: ' + user.token)

                                user.currentProject = new Project()

                                if (user.id_projects === null) {
                                    user.id_projects = -1
                                }

                                // Die Projektliste fertig machen
                                if (user.projects !== null) {

                                    // Projektdaten und IDs korrigieren und das akutelle Projekt referenzieren
                                    user.projects.forEach((project) => {
                                        project.id = parseInt(project.id.toString(), 10)
                                        project.inaktiv = (project.inaktiv.toString() === '1' ? 1 : 0)
                                        project.messeprojekt = (project.messeprojekt.toString() === '1' ? 1 : 0)

                                        if (project.id.toString() === user.id_projects.toString()) {
                                            user.currentProject = project
                                            user.id_projects = project.id
                                        }
                                    })

                                    // Kein aktives Projekt gefunden. Wenn nur eine Projekt da ist, wird das jetzt das aktive.
                                    if (user.currentProject.id === -1) {
                                        if (user.projects.length === 1) {
                                            user.currentProject = user.projects[0]
                                            user.id_projects = user.currentProject.id
                                        }
                                    }

                                    this.userService.setUser(user, 'login')

                                    if (user.currentProject.id === -1) {

                                        // Kein Projekt bestimmt => Weiter zur Projektauswahl
                                        this.router.navigate(['/projects'], { replaceUrl: true, skipLocationChange: true })
                                        // window.location.reload()
                                    } else {

                                        // Projekt bestimmt => Weiter zum Home-Bildschirm

                                        // Login hat funktioniert, Projekt ist bestimmt, jetzt die aktualisierung der Files-Liste antriggern.
                                        this.filesService.fetchFilesList('login.login')

                                        this.router.navigate(['/'], { replaceUrl: true, skipLocationChange: true })
                                        // window.location.reload()
                                    }

                                } else {
                                    this.alert = this.languageService.get('de', 'ACCOUNT_NOT_AVAILABLE') // Konto existiert, hat aber noch keine Projekte
                                }
                            } else if (result.status === 'ERROR') {
                                this.alert = this.languageService.get('de', result.data) // Server antwortet nicht- Ausgabe Fehlermeldung vom Server im Debugmodus
                            } else {
                                this.alert = this.languageService.get('de', 'SERVER_NOT_AVAILABLE') // Server antwortet nicht
                            }
                            this.waitForServer = false
                        },

                        (error: Result) => {
                            if (environment.debug === true) {
                                this.alert = error.data // Server antwortet nicht- Ausgabe Fehlermeldung vom Server im Debugmodus
                            } else {
                                this.alert = this.languageService.get('de', 'SERVER_NOT_AVAILABLE') // Server antwortet nicht
                            }
                            this.waitForServer = false
                        },
                    )
                }
            } else {
                this.waitForServer = false
            }
        }
    }

    // NEUES PASSWORT ------------------------------------------------------------------------------------------

    public newPassword() {
        if (this.waitForServer === false) {
            this.waitForServer = true
            this.alert = ''
            if (this.username === '') {
                this.alert = this.languageService.get('de', 'USERNAME_MISSING')
                this.waitForServer = false
            } else {
                const httpData = new HttpData(new User(), 'password_new')
                httpData.data['username'] = this.username
                this.httpService.getDataByPost(httpData, 'login.newPassword').subscribe(
                    (result: Result) => {
                        if (result.status === 'OK') {
                            this.alert = this.languageService.get('de', 'PASSWORD_NEW_SENT')
                            this.waitForServer = false
                        } else {
                            this.alert = this.languageService.get('de', result.data)
                            this.waitForServer = false
                        }
                    },
                    (error: Result) => {
                        this.waitForServer = false
                    },
                )
            }
        }
    }

    // HELPER --------------------------------------------------------------------------------------------------

    public clearCurrentUser() {
        this.userService.clearUser() // Den bisherigen User löschen
        this.storageService.clear() // Alles Daten im Speicher löschen
        if (environment.debug === true) {
            console.log('USER CLEARED')
        }
    }

    // Setzt den Focus auf das übergebene Eingabefeld
    public jumpToField(field: string) {
        $('#' + field).focus()
    }

}
