<div *ngIf="waitForServer === true" class="fd_loading_spinner_stage_background"></div>
<div *ngIf="waitForServer === true" class="fd_loading_spinner_stage">
    <div class="fd_loading_spinner"></div>
</div>

<div id="login" class="p-4">

    <img src="/assets/lorenz-personal-logo.jpg" class="w-100 mx-auto">

    <div *ngIf="version!='LIVE'" class="mt-5 text-center">
        <p>{{version}}</p>
    </div>

    <form class="mt-4 mt-sm-3">

        <div class="form-group">
            <label for="username">Benutzername</label>
            <input autocomplete="off" type="text" id="username" name="username" class="form-control" [(ngModel)]="username" (keyup.enter)="jumpToField('password')">
        </div>

        <div class="form-group">
            <label for="password">Passwort</label>
            <input autocomplete="off" type="password" id="password" name="password" class="form-control" [(ngModel)]="password" (keyup.enter)="login()">
        </div>

        <div *ngIf="alert!=''" class="mt-3 alert alert-warning">
            {{alert}}
        </div>

        <div class="mt-5">
            <button type="button" class="btn btn-primary btn-block btn-lg" (click)="login()">Anmelden</button>
        </div>

        <div class="mt-5 text-right">
            <button type="button" class="btn btn-outline-primary btn-lg" (click)="newPassword()">Neues Passwort anfordern</button>
        </div>

        <!-- 2018-07-31 - Deaktiviert, s. Mails

		<div class="form-check text-center mt-5">
			<input type="checkbox" class="form-check-input" name="stayLogged" [(ngModel)]="stayLogged">
			<label class="form-check-label" for="stayLogged">Angemeldet bleiben</label>
		</div>

		-->
    </form>

</div>