/**
 * Komponente für die Anzeige der Arbeitszeitdaten - View "Jahr"
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-03-01
 * Revision: 2019-10-25
 *
 */



import { environment } from '../../environments/environment';

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DisplayService, ToolsService, UserService } from '../_services/_index';

import { Display } from '../_models/_index';

declare var window: any;
declare var scrollmasterMove: any;



@Component({
  selector: 'app-home-year',
  templateUrl: 'home-year.component.html',
  styleUrls: ['home-year.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomeYearComponent implements OnChanges {



  @Input() display: Display; // DAS AKTUELLE JAHR (erstversorgt von der Elternkomponente)
  @Input() section: number; // Die aktuelle Sektion

  public isInactive = false; // True, denn das Projekt inaktiv ist

  public formattedDate = '';



  public constructor(
    public displayService: DisplayService,
    public toolsService: ToolsService,
    public userService: UserService) { }

  public ngOnChanges(changes: SimpleChanges) {

    this.toolsService.debugInfo('home-year', 'ngOnChanges', 'LIFECYCLE');

    const user = this.userService.getUser();
    this.display = this.displayService.getDisplay();

    this.formattedDate = this.toolsService.formatDate(this.display.date);
    this.isInactive = (user.currentProject.inaktiv === 1);
  }



  public goToMonat(datum: string) {
    if (environment.debug === true) { console.log('JUMP TO: ' + datum); }
    window.scrollmaster.zone.run(() => { window.scrollmaster.wipe(datum, this.section, 2, this.section, 1); });
    scrollmasterMove(this.section, 1);
  }
}
