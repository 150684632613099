/**
 * Service für das Files-Handling und den Dialog dazu
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2020-10-28
 *
 */

import { HttpHeaders } from '@angular/common/http'
import { ElementRef, Injectable } from '@angular/core'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { BehaviorSubject } from 'rxjs'
import { FileDocument } from '../_models/file-document.model'
import { HttpData, Result, User } from '../_models/_index'
import { HttpService } from './http.service'
import { StorageService } from './storage.service'
import { ToolsService } from './tools.service'
import { UserService } from './user.service'

@Injectable()
export class FilesService {

    // Abonnement-Angebote
    public filesList = new BehaviorSubject<FileDocument[]>(null) // Die aktuelle Liste mit allen Files
    public filesCount = new BehaviorSubject<number>(0) // Die aktuelle Anzahl an Files
    public filesCountUnread = new BehaviorSubject<number>(0) // Die aktuelle Anzahl an Files, die noch nicht abgerufen/gelesen wurden

    private modals: Map<string, ElementRef> = new Map<string, ElementRef>() // Map mit den Referenzen auf die Modals
    private modalsData: Map<string, any> = new Map<string, any>() // Map mit den Referenzen auf die Daten für die Modals

    // LIFECYCLE -----------------------------------------------------------------------------------------------

    public constructor(
        private httpService: HttpService,
        private modalService: NgbModal,
        private storageService: StorageService,
        private toolsService: ToolsService,
        private userService: UserService,
    ) {
        this.toolsService.debugInfo('FilesService', 'constructor', 'LIFECYCLE')
    }

    // METHODEN ZUR SERVERKOMMUNIKATION ------------------------------------------------------------------------

    // Ruft eine Datei vom Server ab ("caller" wird beim Debugging als Info ausgegeben).
    public downloadFile(file: FileDocument, caller = ''): any {

        // https://medium.com/techinpieces/blobs-with-http-post-and-angular-5-a-short-story-993084811af4
        // https://dev.to/nombrekeff/download-file-from-blob-21ho

        if (file) {
            const user = this.userService.getUser()

            caller = (caller ? `${caller} / FilesService.downloadFile` : caller)

            const httpData = new HttpData(user, 'file-download')
            httpData.data['user'] = user
            httpData.data['file_id'] = file.id

            const headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Accept: 'application/json',
            })

            this.httpService.getDataByPost(httpData, caller, { headers, responseType: 'blob' as 'json' })
                .subscribe((response: any) => {

                    const blob = new Blob([response as any as Blob], { type: 'octet/stream' })

                    const blobUrl = URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = blobUrl
                    link.download = file.filename
                    document.body.appendChild(link)
                    link.dispatchEvent(
                        new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window,
                        }),
                    )
                    document.body.removeChild(link)

                    this.fetchFilesList()
                })
        }
    }

    // Holt bzw. aktualisiert die Liste der relevanten Files vom Server und setzt alle Werte.
    public fetchFilesList(caller = '') {
        const user = this.storageService.get('User', new User())

        const httpData = new HttpData(user, 'userfileslist')
        httpData.data['user'] = user
        this.httpService.getDataByPost(httpData, caller + '.fetchFilesList')
            .subscribe((response: Result) => {

                if (response.status.toLocaleUpperCase() === 'OK') {
                    const filesList = response.data as FileDocument[]
                    this.filesList.next(filesList)
                    this.filesCount.next(filesList ? filesList.length : 0)
                    this.filesCountUnread.next(filesList ? filesList.filter((file) => !file.first_view).length : 0)
                }

            })
    }

    // METHODEN ZU DEN MODALS ----------------------------------------------------------------------------------

    // Registriert ein Modal für die Verwendung mit dem Service.
    public registerModal(key: string, elementRef: ElementRef) {
        this.toolsService.debugInfo('FilesService', `registerModal(${key})`, 'MODALS')
        this.modals.set(key, elementRef)
    }

    // Öffnet ein registriertes Modal - options => https://ng-bootstrap.github.io/#/components/modal/api
    public openModal(key: string, options = {}): NgbModalRef {
        if (this.modals.has(key)) {

            // Property 'data' für das Modal verfügbar machen und aus den Options löschen.
            if (this.modalsData.has(key)) {
                this.modalsData.delete(key)
            }
            if (options && 'data' in options) {
                this.modalsData.set(key, options['data'])
                delete options['data']
            }
            return this.modalService.open(this.modals.get(key), options)
        }
    }

    // Liefert das Datenobjekt zu einem Modal oder {}
    public getModalData(key: string): any {
        return this.modalsData.has(key) ? this.modalsData.get(key) : {}
    }

}
