<div *ngIf="loading === true" class="fd_loading_spinner_stage_background"></div>
<div *ngIf="loading === true" class="fd_loading_spinner_stage">
    <div class="fd_loading_spinner"></div>
</div>

<app-functions title="Projektauswahl"></app-functions>

<div id="projects">
    <div class="row w-100 m-0 project" *ngFor="let project of projects" (click)="onSelect(project)">
        <div class="col-12 px-3 py-1">

            <div *ngIf="project.datum !== null">
                <span class="date">{{ toolsService.convertIntDateToUserDate(project.datum) }}</span><br>
            </div>

            <div *ngIf="project.datum === null">
                <span class="date">--.--.----</span><br>
            </div>

            <span class="projectname pl-3">{{ project.projectname }}</span>
            <span *ngIf="debug === true"> ({{ project.id }})</span>
            <br>
            <span class="subprojectname pl-3">{{ project.subprojectname }}</span>

        </div>
    </div>
</div>