/**
 * Service für das USER-Objekt
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-01-17
 * Revision: 2019-12-02
 *
 */

import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { HttpData, Project, User } from '../_models/_index'
import { HttpService } from '../_services/http.service'
import { StorageService } from '../_services/storage.service'
import { ToolsService } from '../_services/tools.service'

@Injectable()
export class UserService {

    // Den globalen App-User gibt es NUR im lokalStorage! Es darf nur einen geben ;-)

    public constructor(
        private httpService: HttpService,
        private storageService: StorageService,
        private toolsService: ToolsService,
    ) { }

    // Speichert das globale USER-Objekt im LocalStorage und sendet es an den Server
    public setUser(user: User, caller = ''): User {
        caller += ((caller === '') ? '' : '-')

        if (user !== null) {

            if (user.projects === null) {
                user.currentProject = new Project()
            }

            user.id_projects = user.currentProject.id

            this.storageService.set('User', JSON.stringify(user)) // Daten lokal speichern

            // Korrerturen
            if (user.id_projects === -1) { user.id_projects = null }

            // Daten zum Server schicken, wenn der User eingeloggt ist!
            if (this.storageService.get('Salt', 'NULL') !== 'NULL') {
                const httpData = new HttpData(user, 'user')
                httpData.data['user'] = user
                this.httpService.getDataByPost(httpData, caller + 'setUser').subscribe()
            }
        } else {
            if (environment.debug === true) { console.log('ERROR - User NULL in "setUser()"') }
        }

        return user
    }

    // Liefert den globalen User aus dem LocalStorage
    public getUser(): User {
        const user = this.storageService.get('User', new User())

        // Korrenturen
        if (user.language === '') { user.language = 'de' }
        if (user.id_projects === null) { user.id_projects = -1 }

        return user
    }

    // Löscht den globalen User im LocalStorage
    public clearUser() {
        this.storageService.remove('User')
    }
}
