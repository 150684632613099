// TEST SETTINGS
export const environment = {
  appversion: '2021-02-03/1', // Version fürs Debugging

  version: 'TEST', // VERSION
  storagePrefix: 'lorenz-test', // Prefix fürs Webstorage

  debug: true, // Ausgabe von Debuginformationen (Konsole)
  production: true, // Übersetzen als Produktiversion

  urlRest: '/_PHP_/rest.php',  // Basis für die PHP-REST-Aufrufe
  urlDownloads: 'https://zeit.fd-it.de/_PHP_/',  // Basis für die Downloads der Monatsübersichten
  urlLeitfaedenMessejob: 'https://www.lorenz-personal.de/bilder/Downloads/Film_Video/Messevideos/html/indexnew.html', // Adresse "Leitfäden Messejob Seite"
  urlHilfeFAQ: 'https://mytime.lorenz-personal.de/help/index.html', // Adresse der "Hilfe/FAQ-Seite"

  pingIntervall: 30, // Server-Ping-Intervall in Sekunden
}
