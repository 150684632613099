/**
 * Datentyp für die Projektdaten
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-01-11
 * Revision: 2019-10-14
 *
 */



export class Project {

    public id = -1;
    public projectname = '';
    public subprojectname = '';
    public email = '';
    public datum = ''; // Datum der letzten Zeiterfassung des Users im Projekt
    public messeprojekt = 0; // 1 = "JA"; alles andere = "NEIN"
    public inaktiv = 0; // Inaktives Projekt - 1 = "JA"; alles andere = "NEIN"
    public template = ''; // Unterverzeichnis mit den Template-Dateien für den Tätigkeitsnachweis

}
