/**
 * Storage Service
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2017-12-01
 * Revision: 2019-12-19
 *
 */



import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';



@Injectable()
export class StorageService {



    // Speichert einen Wert im LocalStorage
    set(name: string, data: string) {
        localStorage.setItem(environment.storagePrefix + '.' + name, data);
    }



    // Liefert einen Wert aus LocalStorage oder defaultValue
    get(name: string, defaultValue = null) {
        let data = null;
        try {
            data = JSON.parse(localStorage.getItem(environment.storagePrefix + '.' + name));
        } catch (err) {
            data = localStorage.getItem(environment.storagePrefix + '.' + name);
        }
        if (data === null) {
            data = defaultValue;
        }
        return data;
    }



    // Entfernt einen Wert aus dem LocalStorage
    remove(name: string) {
        let result = false;
        if (this.get(name) !== null) {
            localStorage.removeItem(environment.storagePrefix + '.' + name);
            result = true;
        }
        return result;
    }



    // Löscht ALLE App-Werte aus dem LocalStorage
    clear() {
        const prefix = environment.storagePrefix + '.';

        const delKeys = [];

        for (let idx = 0; idx < localStorage.length; idx++) {
            if (localStorage.key(idx).substr(0, prefix.length) === prefix) {
                delKeys.push(localStorage.key(idx));
            }
        }

        for (let idx = 0; idx < delKeys.length; idx++) {
            localStorage.removeItem(delKeys[idx]);
        }
    }
}
