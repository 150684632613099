<ng-template #filesViewerModal let-modal>

    <div *ngIf="isMp4" class="modal-body">

        <video controls (click)="toggleVideo($event)" #videoPlayer>
            <source [src]="this.fileUrl" type="video/mp4" />
            Browser not supported
        </video>

    </div>

    <div *ngIf="!isMp4" class="modal-body">

        <!-- https://www.npmjs.com/package/ng2-pdf-viewer -->

        <pdf-viewer
            [src]="fileUrl"
            [original-size]="false"
            [show-all]="false"
            [page]="page"
            (after-load-complete)="afterLoadComplete($event)">
        </pdf-viewer>

    </div>

    <div class="modal-footer">

        <div *ngIf="totalPages > 1" class="mr-auto">
            <button (click)="prevPage()" [disabled]="page === 1">
                <fa-icon [icon]="faChevronLeft"></fa-icon>
            </button>
            <span class="pagination">{{ page }} / {{ totalPages }}</span>
            <button (click)="nextPage()" [disabled]="page === totalPages">
                <fa-icon [icon]="faChevronRight"></fa-icon>
            </button>
        </div>

        <button type="button" class="btn btn-primary" (click)="modal.close()">Schließen</button>

    </div>

</ng-template>
