<div *ngIf="loading === true" class="fd_loading_spinner_stage_background"></div>
<div *ngIf="loading === true" class="fd_loading_spinner_stage">
    <div class="fd_loading_spinner"></div>
</div>

<app-functions id="navbar-getter" title="{{display.projectname}}" displayFileDialog="true"></app-functions>

<div id="home">
    <div id="fullpage" class="col-12 p-0 m-0">
        <div class="section" *ngFor="let sec of [0,1,2]">
            <div class="slide" *ngFor="let sld of [0,1,2]">
                <div *ngIf="alert!=''" class="mt-5 alert alert-warning">
                    {{alert}}
                </div>
                <div *ngIf="sessionStopped === true" class="p-4">
                    <button class="btn btn-primary btn-block mt-1" (click)="login()">zum Login</button>
                </div>
                <div>
                    <app-home-day *ngIf="scrollMasterView.row[sec].item[sld].typ=='tag'" [display]='scrollMasterView.row[sec].item[sld]' [section]='sec'></app-home-day>
                    <app-home-month *ngIf="scrollMasterView.row[sec].item[sld].typ=='monat'" [display]='scrollMasterView.row[sec].item[sld]' [section]='sec'></app-home-month>
                    <app-home-year *ngIf="scrollMasterView.row[sec].item[sld].typ=='jahr'" [display]='scrollMasterView.row[sec].item[sld]' [section]='sec'></app-home-year>
                </div>
                <div *ngIf="debug === true && display !== null" class="debuginfo">Section {{sec}} | Slide {{sld}} | User #{{display.id_user}} | Projekt #{{display.id_project}}</div>
            </div>
        </div>
    </div>
</div>


<!-- MODAL FÜR DIE DATEIANZEIGE ----------------------------------------------------------------------------------------->

<app-files-dialog></app-files-dialog>