/**
 * Service für das Handling des DISPLAY-Objekts
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-02-01
 * Revision: 2019-10-18
 *
 */

import { EventEmitter, Injectable, Output, Directive } from '@angular/core'
import { environment } from '../../environments/environment'
import { Display } from '../_models/_index'
import { StorageService } from '../_services/storage.service'
import { ToolsService } from '../_services/tools.service'

declare var $: any

@Directive()
@Injectable()
export class DisplayService {

    @Output() public cancelSession: EventEmitter<boolean> = new EventEmitter() // Event um die Session zu stoppen
    @Output() public checkTimetrackerJob: EventEmitter<boolean> = new EventEmitter() // Event um den Timer für die Zeiterfassung zu überprüfen und ggf. zu starten
    @Output() public midnightRefresh: EventEmitter<boolean> = new EventEmitter() // Event um den "Midnight-Refresh" auszulösen
    @Output() public refreshView: EventEmitter<boolean> = new EventEmitter() // Event um die Zeitansicht (home) bei Bedarf"von außen zu aktualisieren
    @Output() public timeStarter: EventEmitter<boolean> = new EventEmitter() // Event um die Zeiterfassung bei Bedarf"von außen" zu starten
    @Output() public timeStopper: EventEmitter<boolean> = new EventEmitter() // Event um die Zeiterfassung bei Bedarf"von außen" zu stoppen

    // LIFECYCLE -----------------------------------------------------------------------------------------------

    public constructor(
        private storageService: StorageService,
        private toolsService: ToolsService,
    ) { }

    // DAS GLOBALE DISPLAY OBJEKT - ES DARF NUR EINES GEBEN ----------------------------------------------------

    // WICHTIG: Das Display hier auf keinen Fall in der DB speichern, weil es nur für die Anzeige verwendet werden darf!

    // Speichert das globale DISPLAY-Objekt (aka die aktuelle Anzeige) im LocalStorage
    public setDisplay(display: Display, caller = ''): Display {

        caller += ((caller === '') ? '' : '-')

        if (display !== null) {

            // Daten lokal speichern
            this.storageService.set('Display', JSON.stringify(display))

            // "Heute" auch den Cache aktualisieren
            if ((caller.indexOf('setTodayCache') === -1) && (display.date === this.toolsService.getDateString()) && (display.typ === 'tag')) {
                this.setTodayCache(display, 'setDisplay')
            }

            if (environment.debug === true) { console.log('SERVICE - ' + caller + ' => setDisplay()') }
        } else {
            if (environment.debug === true) { console.log('ERROR - Display NULL in "setDisplay()"') }
        }

        return display
    }

    // Liefert das globale DISPLAY-Objekt (aka die aktuelle Anzeige) aus dem LocalStorage
    public getDisplay(): Display {
        const result = this.storageService.get('Display', new Display())
        return result
    }

    // Löscht das globale DISPLAY-Objekt im LocalStorage
    public clearDisplay() {
        this.storageService.remove('Display')
    }

    // Setzt das DISPLAY-Objekt für "Heute"
    public setTodayCache(display: Display, caller = ''): Display {

        caller += ((caller === '') ? '' : '-')

        if (display !== null) {
            // Daten lokal speichern
            this.storageService.set('Cache', JSON.stringify(display))

            // "Heute" ggf. auch den normalen Bildschirm aktualisieren
            const helperDisplay = this.getDisplay()
            if ((caller.indexOf('setDisplay') === -1) && (helperDisplay.date === display.date) && (helperDisplay.typ === 'tag')) {
                this.setDisplay(display, 'setTodayCache')
            }

            if (environment.debug === true) { console.log('SERVICE - ' + caller + ' => setTodayCache()') }
        } else {
            if (environment.debug === true) { console.log('ERROR - Display NULL in "setTodayCache()"') }
        }

        return display
    }

    // Liefert das DISPLAY-Objekt für "Heute"
    public getTodayCache(): Display {

        const result = this.storageService.get('Cache', new Display())
        return result
    }

    // EVENTSENDER --------------------------------------------------------------------------------------------

    // Sendet den Event "cancelSession" in den Äther
    public sendCancelSession(): void {
        this.cancelSession.emit()
    }

    // Sendet den Event "checkTimetrackerJob" in den Äther
    public sendCheckTimetrackerJob(): void {
        this.checkTimetrackerJob.emit()
    }

    // Sendet den Event "midnightRefresh" in den Äther
    public sendMidnightRefresh(): void {
        this.midnightRefresh.emit()
    }

    // Sendet den Event "refreshView" in den Äther
    public sendRefreshView(): void {
        this.refreshView.emit()
    }

    // Sendet den Event "timeStarter" in den Äther
    public sendTimeStarter(): void {
        this.timeStarter.emit()
    }

    // Sendet den Event "timeStopper" in den Äther
    public sendTimeStopper(): void {
        this.timeStopper.emit()
    }

    // METHODS ------------------------------------------------------------------------------------------------

    // Prüfung der Pausenzeiten
    public checkBreakTime(breakTime: string, workTime: string): boolean {

        let result = true

        const breakInSekunden = this.toolsService.convertTimeToSeconds(breakTime)
        const workInSekunden = this.toolsService.convertTimeToSeconds(workTime)

        // Bei einer Arbeitszeit von mehr als 6h (ohne Pausenzeiten) muss die Pausenzeit mindesten 30min betragen.
        if ((result === true) && (workInSekunden > (6 * 60 * 60))) {
            result = (breakInSekunden >= (30 * 60))
        }

        // Bei einer Arbeitszeit von mehr als 9h (ohne Pausenzeiten) muss die Pausenzeit mindesten 45min betragen.
        if ((result === true) && (workInSekunden > (9 * 60 * 60))) {
            result = (breakInSekunden >= (45 * 60))
        }

        return result
    }

    // Prüfung der Arbeitszeit
    public checkWorkTime(workTime: string, comment: string): boolean {

        let result = true
        const workInSekunden = this.toolsService.convertTimeToSeconds(workTime)

        // Die tägliche Arbeitszeit darf maximal 10h betragen. Pausen werden dabei nicht eingerechnet.
        if (workInSekunden > (10 * 60 * 60)) {
            result = comment !== '' // Wenn ein Kommentar angegeben ist, darf die 10h Grenze überschritten werden
        }

        return result
    }
}
