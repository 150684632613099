/**
 * Datentyp für die Abwicklung der HTTP-Requests
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-03-13
 * Revision: 2020-12-03
 *
 */

import { environment } from 'src/environments/environment'
import { User } from '../_models/user.model'

export class HttpData {

    public command = '' // Command, der auf dem Server aufgerufen werden soll
    public data: any = null // Die Daten

    public constructor(user: User, command: string) {

        this.command = command
        this.data = {}

        if (user !== null) {
            this.data['_username_'] = user.username
            this.data['_token_'] = user.token
            this.data['_project_id_'] = user.currentProject.id
            this.data['_clientdate_'] = this.getLocalIsoDateTime()
            this.data['_appversion_'] = environment.appversion
        }
    }

    // Liefert das aktuelle Token
    public getToken() {
        if (this.data['_token_'] === '') {
            return '*** NO TOKEN ***'
        } else {
            return this.data['_token_']
        }
    }

    // Liefert das aktuelle Datum und die aktuelle Uhrzeit als STRING im ISO 8601 Format: 2020-01-15T11:34:21+01:00
    private getLocalIsoDateTime() {
        const datum = new Date()
        const tzo = datum.getTimezoneOffset() // z.B. -60 => +01:00 (!)
        const dif = (tzo >= 0 ? '-' : '+')
        const pad = (zahl: number) => {
            const norm = Math.floor(Math.abs(zahl))
            return (norm < 10 ? '0' : '') + norm
        }
        const result = datum.getFullYear() + '-' + pad(datum.getMonth() + 1) + '-' + pad(datum.getDate())
            + 'T' + pad(datum.getHours()) + ':' + pad(datum.getMinutes()) + ':' + pad(datum.getSeconds())
            + dif + pad(tzo / 60) + ':' + pad(tzo % 60)

        return result
    }
}
