/**
 * Guard für den Zugriff auf die Projektseite
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-01-17
 * Revision: 2019-10-28
 *
 */




import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { HttpService, StorageService, ToolsService, UserService } from '../_services/_index';



@Injectable()
export class ProjectGuard implements CanActivate {



    public constructor(
        public httpService: HttpService,
        public storageService: StorageService,
        public toolsService: ToolsService,
        public userService: UserService,
        public router: Router) { }



    public canActivate() {

        this.toolsService.debugInfo('ProjectGuard', 'canActivate');

        let access = false; // GRUNDSÄTZLICH ERSTMAL KEIN ZUGRIFF

        const user = this.userService.getUser();

        const auth_user = (user === null ? '@@@' : user.token);
        const auth_localStorage = this.storageService.get('Salt', '***');

        if (auth_user.toString() === auth_localStorage.toString()) {

            access = true;

        }

        if (access === false) {

            this.router.navigate(['/login'], { replaceUrl: true, skipLocationChange: true });
        } else {

            // Online-Checker bei Bedarf starten
            if (this.httpService.serverStatusCheckerRunning() === false) {
                this.httpService.serverStatusCheckerStart();
            }
        }

        // if (environment.debug === true) {
        //     console.log('PROCESS GUARD - ' + access + ' / auth_user=' + auth_user + ' / auth_localStorage=' + auth_localStorage);
        // }

        return access;
    }
}
