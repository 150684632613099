<div id="home-month">

    <div id="month-{{display.date}}" class="container-fluid">

        <div class="row">

            <div id="date-{{display.date}}" class="col-12 m-0 px-0 py-2 text-center" [attr.title]="debug ? debuginfos1 : null" [ngClass]="{'text-muted': isInactive === true}">
                {{formattedDate}}
            </div>

            <div class="col-12 m-0 px-2 py-0">
                <table id="mainTable-{{display.date}}" class="table table-bordered" [style.fontSize.px]="fontSizeTable">
                    <thead>
                        <tr [ngClass]="{'text-muted': isInactive === true}">
                            <th style="width: 10%;" class="border-left-0">Tag</th>
                            <th style="width: 20%;">von</th>
                            <th style="width: 20%;">bis</th>
                            <th style="width: 20%;">h</th>
                            <th style="width: 20%;">Pause</th>
                            <th style="width: 10%;" class="delete border-right-0">&nbsp;&nbsp;&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor='let item of display.view.days' [ngClass]="{'text-muted': isBlocked === true, 'text-danger': item.checkOk == false}">
                            <td (click)="goToTag(item.date)" class="border-left-0" [ngClass]="{'text-muted': item.state == 'NEU'}">{{item.day}}</td>

                            <td *ngIf="item.state=='START' || item.state=='STOP'" (click)="goToTag(item.date)">{{item.begin}}</td>
                            <td *ngIf="item.state=='START' || item.state=='STOP'" (click)="goToTag(item.date)">{{item.end}}</td>
                            <td *ngIf="item.state=='START' || item.state=='STOP'" (click)="goToTag(item.date)">{{item.work}}</td>
                            <td *ngIf="item.state=='START' || item.state=='STOP'" (click)="goToTag(item.date)">{{item.break}}</td>

                            <td *ngIf="item.state=='NEU'" (click)="goToTag(item.date)" class="text-muted">--:--</td>
                            <td *ngIf="item.state=='NEU'" (click)="goToTag(item.date)" class="text-muted">--:--</td>
                            <td *ngIf="item.state=='NEU'" (click)="goToTag(item.date)" class="text-muted">--:--</td>
                            <td *ngIf="item.state=='NEU'" (click)="goToTag(item.date)" class="text-muted">--:--</td>

                            <td *ngIf="(item.state != 'START') && (item.state != 'STOP') && (item.state != 'NEU')" colspan="4" (click)="goToTag(item.date)" class="text-center">{{languageService.get('de', 'STATE_' + item.state)}}</td>

                            <td *ngIf="(item.state != 'START') && (item.state != 'NEU') && (isBlocked === false) && (isInactive === false)" (click)="delete(item.date)" class="delete border-right-0"></td>
                            <td *ngIf="(item.state == 'START') || (item.state == 'NEU') || (isBlocked === true) || (isInactive === true)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div id="button-{{display.date}}" *ngIf="isInactive === false" class="col-12 m-0 p-0">

                <div id="buttons" class="m-0 p-0 row justify-content-between">

                    <!-- Abrufen -->

                    <div class="col-6" *ngIf="canAbrufen === 0">
                        <a *ngIf="isAppTitan === false" class="col-6" [href]="downloadPDF" target="lorenz">
                            <button class="w-100 btn btn-primary" [style.fontSize.px]="fontSizeButtons" (click)="activity_report_view(display.date)">Stundennachweis als PDF</button>
                        </a>
                        <a *ngIf="isAppTitan === true" class="col-6" [href]="mailPDF">
                            <button class="w-100 btn btn-primary" [style.fontSize.px]="fontSizeButtons">Stundennachweis als PDF</button>
                        </a>
                    </div>

                    <div class="col-6" *ngIf="canAbrufen === 1">
                        <a *ngIf="isAppTitan === false" class="col-6">
                            <button class="w-100 btn disabled" [style.fontSize.px]="fontSizeButtons" disabled="disabled">Stundennachweis als PDF</button>
                        </a>
                        <a *ngIf="isAppTitan === true" class="col-6">
                            <button class="w-100 btn disabled" [style.fontSize.px]="fontSizeButtons" disabled="disabled">Stundennachweis als PDF</button>
                        </a>
                    </div>

                    <!-- Freigeben / Versenden -->

                    <div class="col-6" *ngIf="canLorenz === 0">
                        <button class="w-100 btn btn btn-primary" [style.fontSize.px]="fontSizeButtons" (click)="activity_report_send(display.date)">{{lorenzButtonText}}</button>
                    </div>

                    <div class="col-6" *ngIf="canLorenz === 1">
                        <button class="w-100 btn btn disabled" [style.fontSize.px]="fontSizeButtons" disabled="disabled">{{lorenzButtonText}}<span *ngIf="display.view.transfer_lorenz !== ''"> {{transfer_lorenz}}</span>
                        </button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>