/**
 * Application Routing
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-01-17
 * Revision: 2019-12-12
 *
 */

import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './home/home.component'
import { LoginComponent } from './login/login.component'
import { PasswordComponent } from './password/password.component'
import { ProjectsComponent } from './projects/projects.component'
import { AccessGuard, ProjectGuard } from './_guards/_index'

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent, canActivate: [AccessGuard] },
  { path: 'login', pathMatch: 'full', component: LoginComponent },
  { path: 'password', pathMatch: 'full', component: PasswordComponent, canActivate: [AccessGuard] },
  { path: 'projects', pathMatch: 'full', component: ProjectsComponent, canActivate: [ProjectGuard] },
  { path: '**', redirectTo: '/' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() { }
}
