/**
 * Komponente für die Projektauswahl
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-01-11
 * Revision: 2019-12-02
 *
 */

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../environments/environment'
import { HttpData, Project, Result } from '../_models/_index'
import { DisplayService, HttpService, FilesService, StorageService, ToolsService, UserService } from '../_services/_index'

declare var $: any

@Component({
  selector: 'app-projects',
  templateUrl: 'projects.component.html',
  styleUrls: ['projects.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsComponent implements OnInit, AfterViewInit {

  public projects: Project[] // Array für die Projekte
  public selectedProject: Project // ID des ausgewählten Projekts

  public loading = false // Ladebildschirm anzeigen oder nicht

  public debug: boolean // Debugginginfos

  @ViewChild('functions') public functions: ElementRef

  // LIFECYCLE -----------------------------------------------------------------------------------------------

  public constructor(
    private router: Router,
    public changeDetectorRef: ChangeDetectorRef,
    private displayService: DisplayService,
    private httpService: HttpService,
    private filesService: FilesService,
    private storageService: StorageService,
    private toolsService: ToolsService,
    private userService: UserService,
  ) { }

  public ngOnInit() {

    this.toolsService.debugInfo('projects', 'ngOnInit', 'LIFECYCLE')

    this.debug = environment.debug
  }

  public ngAfterViewInit() {

    this.toolsService.debugInfo('projects', 'ngAfterViewInit', 'LIFECYCLE')

    const siteHeight = this.toolsService.convertStringToInt($('body').height(), 0)
    const captionHeight = this.toolsService.convertStringToInt($('.navbar').outerHeight(), 0)
    const contentHeight = siteHeight - captionHeight

    $('#projects').css('max-height', contentHeight + 'px')

    if (environment.debug === true) {
      console.log('contentHeight ' + contentHeight + 'px')
    }

    this.loading = true
    this.changeDetectorRef.detectChanges() // Wichtig, sonst gibt's keinen "Loading Spinner"

    // Die aktuelle Projektliste vom Server holen
    const user = this.userService.getUser()
    const httpData = new HttpData(user, 'projekte')
    this.httpService.getDataByPost(httpData, 'projects.ngAfterViewInit').subscribe(
      (result: Result) => {
        if (result.status === 'OK') {
          const tempUser = this.userService.getUser()
          tempUser.projects = result.data as Project[]
          this.userService.setUser(tempUser, 'projects.ngAfterViewInit')

          // Projektdaten und IDs korrigieren und das akutelle Projekt referenzieren

          tempUser.currentProject = new Project()

          if (tempUser.id_projects === null) {
            tempUser.id_projects = -1
          }

          tempUser.projects.forEach((project) => {
            project.id = parseInt(project.id.toString(), 10)
            project.inaktiv = (project.inaktiv.toString() === '1' ? 1 : 0)
            project.messeprojekt = (project.messeprojekt.toString() === '1' ? 1 : 0)

            if (project.id.toString() === tempUser.id_projects.toString()) {
              tempUser.currentProject = project
              tempUser.id_projects = project.id
            }
          })

          // Kein aktives Projekt gefunden. Wenn nur eine Projekt da ist, wird das jetzt das aktive.
          if (tempUser.currentProject.id === -1) {
            if (tempUser.projects.length === 1) {
              tempUser.currentProject = tempUser.projects[0]
              tempUser.id_projects = tempUser.currentProject.id
            }
          }

          this.projects = tempUser.projects // DIE AKTUALISIERTE PROJEKTLISTE

          this.loading = false

        } else {
          if (environment.debug === true) { console.log('ERROR: ServerStatus: ' + result.data) }
          this.loading = false
        }
      },
      (error: Result) => {
        if (environment.debug === true) { console.log('ERROR: ServerStatus: ' + error.data) }
      },
      () => {
        this.changeDetectorRef.detectChanges() // Wichtig, sonst gibt's einen "ExpressionChangedAfterItHasBeenCheckedError"
      },
    )
  }

  // METHODS ------------------------------------------------------------------------------------------------

  // Öffnet das Funktionenmenü
  public openFunctions() {
    const user = this.userService.getUser()
    this.projects = user.projects
    this.functions.nativeElement.open()
  }

  // Projektauswahl
  public onSelect(project: Project) {
    let user = this.userService.getUser()

    if ((project !== null) && ((user.currentProject === null) || (user.currentProject.id !== project.id))) {

      // Zeiterfassung im aktuellen/alten Projekt stoppen.
      if ((user.currentProject !== null) && (user.timetracking === 1)) {
        this.toolsService.debugInfo('projects', 'onSelect', 'METHOD', 'sendTimeStopper')

        // 2020-02-20 - BUGFIXING
        // this.displayService.sendTimeStopper();
        user.timetracking = 0
        this.userService.setUser(user, 'projects.onSelect')
        this.zeiterfassungStop()
        user = this.userService.getUser()
      }

      // Userdasten aktualisieren und speichern
      user.currentProject = project
      user.id_projects = project.id
      user.nav_date = this.toolsService.getDateString()
      user.nav_today = 1
      this.userService.setUser(user, 'projects.onSelect')

      // Login hat funktioniert, Projekt ist bestimmt, jetzt die Aktualisierung der Files-Liste antriggern.
      this.filesService.fetchFilesList('projects.onSelect')

      // Die Haupansicht refreshen.
      this.displayService.sendRefreshView()
    }

    // Zurück zur Tagessicht.
    this.router.navigate(['/'], { replaceUrl: true, skipLocationChange: true })
  }

  // AB HIER KOMMT GANZ GROSSER MIST, ABER ES MUSSTE SCHNELL GEHEN...

  // Stoppen der Zeiterfassung ist "autostop === true" wir Status "AUTOSTOP" gesetzt, sonst "STOP"
  public zeiterfassungStop(autostop: boolean = false) {

    const jetzt = this.toolsService.getTimeStringHM()

    const user = this.userService.getUser()
    user.timetracking = 0
    this.userService.setUser(user)

    let todayCache = this.displayService.getTodayCache()

    todayCache.view.state = 'STOP'

    // Zeitsendetimer löschen
    this.stopServerTimer()

    if (autostop === false) {

      // NORMALE ZEITAKTUALISIERUNG

      todayCache.view.lastStop = jetzt
      todayCache.view.end = jetzt

      todayCache = this.displayService.setTodayCache(todayCache)

      // Pause und Arbeitszeit auf Gültigkeit prüfen, wenn wir auf "Heute" sind
      // this.display = this.displayService.getDisplay();
      // if ((this.display.date === this.toolsService.getDateString()) && (this.display.typ === 'tag')) {
      //   this.checkBreakTimeOk = this.displayService.checkBreakTime(this.display.view.break, this.display.view.work);
      //   this.checkWorkTimeOk = this.displayService.checkWorkTime(this.display.view.work, this.display.view.comment);
      // }

      this.displayArbeitszeit()

      // Die Tagesdaten an den Server senden
      const httpData = new HttpData(user, 'time')
      httpData.data['display'] = this.displayService.getTodayCache()
      httpData.data['debug'] = 'homeDay.zeiterfassungStop'
      this.httpService.getDataByPost(httpData, 'home-day.zeiterfassungStop').subscribe()

    } else {

      // // MIDNIGHT AUTOSTOP

      // todayCache.view.lastStop = '23:59';
      // todayCache.view.end = todayCache.view.lastStop;

      // if (todayCache.view.comment.substr(0, 8) !== 'AUTOSTOP') {
      //   todayCache.view.comment = 'AUTOSTOP' + (todayCache.view.comment === '' ? '' : ' - ' + todayCache.view.comment);
      // }

      // todayCache = this.displayService.setTodayCache(todayCache);

      // const tempUser = this.userService.getUser();
      // tempUser.nav_date = todayCache.date;
      // tempUser.nav_today = 0;
      // this.userService.setUser(tempUser);

      // this.displayService.sendRefreshView();
    }

    //   // Debuginfos aktualisieren
    //   this.setDebugInfo();
  }

  public displayArbeitszeit() {

    if (this.debug) {
      console.log('<CLONE> METHOD: homeDay.displayArbeitszeit ' + this.toolsService.getTimeStringHMS())
    }

    const todayDisplay = this.displayService.getTodayCache()

    if (todayDisplay.view.state !== 'NEU') {
      todayDisplay.view.begin = todayDisplay.view.firstStart

      // Wenn die Zeiterfassung läuft, wird die Endzeit ausgeblendet
      if (todayDisplay.view.state === 'START') {
        todayDisplay.view.end = '--:--'
      } else {
        todayDisplay.view.end = todayDisplay.view.lastStop
      }
      todayDisplay.view.break = this.toolsService.convertSecondsToHM(todayDisplay.view.secondsBreak)
      todayDisplay.view.secondsWork = this.toolsService.convertTimeToSeconds(todayDisplay.view.lastStop) - this.toolsService.convertTimeToSeconds(todayDisplay.view.firstStart) - todayDisplay.view.secondsBreak
      todayDisplay.view.work = this.toolsService.convertSecondsToHM(todayDisplay.view.secondsWork)
    }

    // Cache aktualisieren
    this.displayService.setTodayCache(todayDisplay)

    // "HEUTE" auf das angezeigte DISPLAY-Objekt übertragen, wenn wir in der Tagessicht sind
    const tempDisplay = this.displayService.getDisplay()
    // this.isHeute = (tempDisplay.date === this.toolsService.getDateString());

    // this.setDisplayTimeSuffix();

    if (this.debug) { console.log('<CLONE> CACHE REFRESH ' + this.toolsService.getTimeStringHMS()) }

    // // Debuginfos aktualisieren
    // this.setDebugInfo();
  }

  // Timer für die Datensendung an den Server stoppen/löschen.
  public stopServerTimer() {
    const timerSendData = this.storageService.get('Timetracker', null)
    if (timerSendData !== null) {
      const user = this.userService.getUser()
      if (environment.debug === true) { console.log('<CLONE> TIMER: Zeiterfassung END (' + this.toolsService.getTimeStringHMS() + ' - User/Projekt #' + user.id + '/#' + user.currentProject.id + ' - TimerID #' + timerSendData + ')') }
      clearTimeout(timerSendData)
      this.storageService.remove('Timetracker')
      this.storageService.remove('TimetrackerDate')
    }
  }

}
