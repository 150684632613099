/**
 * Tools Service
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-01-17
 * Revision: 2020-01-15
 *
 */

import { environment } from '../../environments/environment'

import { Injectable } from '@angular/core'

@Injectable()
export class ToolsService {

    public constructor() { }

    // METHODS: Datum & Zeit -----------------------------------------------------------------------------------

    // Wandelt ein Datum (String) im Format "H:M", "H:MM", "HH:M" oder "HH:MM" in das Format "HH:MM" um. Im Fehlerfall wird "00:00" geliefert.
    public checkTimeFormat(datum: string) {
        let result = '00:00'
        if (datum !== null) {
            const help = datum.split(':')
            if (help.length === 2) {
                result = this.padLeft(help[0], '0', 2).substr(0, 2) + ':' + this.padLeft(help[1], '0', 2).substr(0, 2)
            }
        }
        return result
    }

    // Wandelt ein Datum (String) im Format "YYYY-MM-DD" in das Format "TT.MM.JJJJ" um.
    public convertIntDateToUserDate(datum: string, vorgabe: string = '***HEUTE***') {
        let result = ((vorgabe === '***HEUTE***') ? this.getDateString() : vorgabe)
        if ((datum !== null) && (datum.length === 10)) {
            const help = datum.split('-')
            if (help.length === 3) {
                result = help[2] + '.' + help[1] + '.' + help[0]
            }
        }
        return result
    }

    // Rechnet Sekunden (Zahl) in das Format HH:MM um.
    public convertSecondsToHM(numSeconds: number) {
        if ((numSeconds === null) || (numSeconds < 0)) {
            numSeconds = 0
        }
        return this.convertSecondsToHMS(numSeconds).substr(0, 5)
    }

    // Rechnet Sekunden (Zahl) in das Format HH:MM:SS um.
    public convertSecondsToHMS(numSeconds: number) {
        if ((numSeconds === null) || (numSeconds < 0)) {
            numSeconds = 0
        }

        const numHours = Math.floor(numSeconds / 3600)
        const hours = ((numHours < 10) ? '0' : '') + numHours.toString()
        numSeconds = numSeconds - numHours * 3600

        const numMinutes = Math.floor(numSeconds / 60)
        const minutes = ((numMinutes < 10) ? '0' : '') + numMinutes.toString()
        numSeconds = numSeconds - numMinutes * 60

        const seconds = ((numSeconds < 10) ? '0' : '') + numSeconds.toString()

        return hours + ':' + minutes + ':' + seconds
    }

    // Wandelt einen String in eine Integer-Zahl um. Geht das nicht, wird "vorgabe" geliefert.
    public convertStringToInt(text: string, vorgabe: number = 0) {
        let result = vorgabe
        try {
            result = parseInt(text, 10)
            if (isNaN(result)) {
                result = vorgabe
            }
        } catch (err) {
            // NIX MACHEN
        }
        return result
    }

    // Rechnet ein Zeit im Format "HH:MM:SS" in Sekunden um.
    public convertTimeToSeconds(zeit: string) {
        const help = zeit.split(':')
        const result = (this.convertStringToInt(help[0]) * 60 * 60) + (this.convertStringToInt(help[1]) * 60) + this.convertStringToInt(help[2])
        return result
    }

    // Wandelt ein Zeit im Format "HH", "HH:MM" oder "HH:MM:SS" in das Format "HH:MM" um.
    public convertToHM(zeit: string) {
        return this.convertToHMS(zeit).substr(0, 5)
    }

    // Konvertiert ein Zeit im Format "HH", "HH:MM" oder "HH:MM:SS" in das Format "HH:MM:SS" um.
    public convertToHMS(zeit: string) {
        let result = ''
        if ((zeit !== null) && ((zeit.length === 5) || (zeit.length === 8))) {
            result = (zeit + ':00:00').substr(0, 8)
        } else {
            result = this.getTimeStringHMS()
        }
        return result
    }

    // Formatiert eine Datum (YYYY-MM-DD, YYYY-MM oder YYYY) für die Anzeige.
    public formatDate(datum: string) {
        let result = ''

        datum = datum.toString()

        if (datum.length === 4) {
            result = datum
        } else if (datum.length === 7) {
            const monate = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
            const monat = this.convertStringToInt(datum.substr(5, 2), 2)
            result = monate[monat - 1] + ' ' + datum.substr(0, 4)
        } else if (datum.length === 10) {
            const wochentag = (new Date(datum)).getDay()
            const tage = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
            return tage[wochentag] + ', ' + datum.substr(8, 2) + '.' + datum.substr(5, 2) + '.' + datum.substr(0, 4)
        }

        return result
    }

    // Liefert das Datum aus "datum" (Date-Objek) im Format "YYYY-MM-DD", "YYYY-MM". Ist "datum" keine gültige Datumsangabe wird "----.--.--" bzw "----.--" geliefert.
    public getDateString(datum: Date = new Date(), format: string = 'YYYY-MM-DD') {
        let result = ''

        let year = '----'
        let month = '--'
        let day = '--'
        if (this.isValidDate(datum)) {
            year = datum.getFullYear().toString()
            month = ((datum.getMonth() < 9) ? '0' : '') + (datum.getMonth() + 1)
            day = ((datum.getDate() < 10) ? '0' : '') + datum.getDate()
        }

        if (format === 'YYYY-MM') {
            result = year + '-' + month
        } else {
            result = year + '-' + month + '-' + day
        }

        return result
    }

    // Liefert das Datum aus "datum" (Date-Objekt) im Format "TT.MM.JJJJ". Ist "datum" keine gültige Datumsangabe wird "--.--.----" geliefert.
    public getDatumString(datum: Date = new Date()) {
        let year = '----'
        let month = '--'
        let day = '--'
        if (this.isValidDate(datum)) {
            year = datum.getFullYear().toString()
            month = ((datum.getMonth() < 9) ? '0' : '') + (datum.getMonth() + 1)
            day = ((datum.getDate() < 10) ? '0' : '') + datum.getDate()
        }
        return day + '.' + month + '.' + year
    }

    // Liefert den Tagesstatus als Text
    public getDayStateText(state: string) {

        let result = ''

        switch (state) {

            case 'START':
            case 'STOP':
                result = 'Zeiterfassung'
                break

            case 'K':
                result = 'Krank'
                break

            case 'KuG':
                result = 'Kurzarbeitergeld'
                break

            case 'U':
                result = 'Urlaub'
                break

            case 'SU':
                result = 'Sonderurlaub'
                break

            case 'FT':
                result = 'Feiertag'
                break

            case 'GZ':
                result = 'Gleitzeit'
                break

            default:
                result = state
        }

        return result
    }

    // Liefert das aktuelle Datum und die aktuelle Uhrzeit als STRING im ISO 8601 Format: 2020-01-15T11:34:21+01:00
    public getLocalIsoDateTime() {
        const datum = new Date()
        const tzo = datum.getTimezoneOffset() // z.B. -60 => +01:00 (!)
        const dif = (tzo >= 0 ? '-' : '+')
        const pad = (zahl) => {
            const norm = Math.floor(Math.abs(zahl))
            return (norm < 10 ? '0' : '') + norm
        }
        const result = datum.getFullYear() + '-' + pad(datum.getMonth() + 1) + '-' + pad(datum.getDate())
            + 'T' + pad(datum.getHours()) + ':' + pad(datum.getMinutes()) + ':' + pad(datum.getSeconds())
            + dif + pad(tzo / 60) + ':' + pad(tzo % 60)

        return result
    }

    // Liefert die Zeit aus "datum" (Date-Objekt) im Format "HH:MM"
    public getTimeStringHM(datum: Date = new Date()) {
        if ((datum === null) || !(datum instanceof Date)) {
            datum = new Date()
        }
        const hours = ((datum.getHours() < 10) ? '0' : '') + datum.getHours()
        const minutes = ((datum.getMinutes() < 10) ? '0' : '') + datum.getMinutes()
        return hours + ':' + minutes
    }

    // Liefert die Zeit aus "datum" (Date-Objekt) im Format "HH:MM:SS"
    public getTimeStringHMS(datum: Date = new Date()) {
        if ((datum === null) || !(datum instanceof Date)) {
            datum = new Date()
        }
        const hours = ((datum.getHours() < 10) ? '0' : '') + datum.getHours()
        const minutes = ((datum.getMinutes() < 10) ? '0' : '') + datum.getMinutes()
        const seconds = ((datum.getSeconds() < 10) ? '0' : '') + datum.getSeconds()
        return hours + ':' + minutes + ':' + seconds
    }

    // Prüft, ob "d" ein gültiges Datum ist
    public isValidDate(d: Date) {
        return (d instanceof Date) && (!isNaN(d.getTime()))
    }

    // Prüf, ob ein Datum im Format YYYY-MM-DD, YYYY-MM oder YYYY gültig ist.
    public validDate(datum: string) {
        if ((datum === null) || (datum === '')) {
            datum = '1234-56-78'
        }
        datum = (datum + '-01-01').substr(0, 10)
        const check = new Date(datum)
        return !isNaN(check.valueOf())
    }

    // METHODS: Verschiedenen Helfer ---------------------------------------------------------------------------

    // Prüft, on ein Tag ein "nicht-Zeiterfassungstag" ist.
    public isSpecialDay(state: string) {
        return (['START', 'STOP', 'NEU'].indexOf(state.toUpperCase()) === -1)
    }

    // Maskiert HTML für den JSON Transport
    public maskHTML(str: string) {
        return String(str).replace(/</g, '@@@|start|@@@').replace(/>/g, '@@@|end|@@@')
    }

    // Liefert eine String "str" der Länge "length" und füllt "str" dabei ggf. mit "char" von Links auf.
    // Hat "str" mindestens die Länge "length", wird "str" geliefert.
    public padLeft(str: string, char: string, length: number) {
        let result = str
        if (str.length < length) {
            result = char.repeat(length) + str
            result = result.substr(result.length - length)
        }
        return result
    }

    // Warte "milliseconds" Millisekunden
    public wait(milliseconds: number) {
        const timeStart = new Date().getTime()
        while (true) {
            const elapsedTime = new Date().getTime() - timeStart
            if (elapsedTime > milliseconds) {
                break
            }
        }
    }

    // METHODS: Debug & Development ----------------------------------------------------------------------------

    // Gibt "komponente.eventName" auf der Konsole aus; "info" wird ggf. angehängt, wenn es nicht leer ist
    public debugInfo(komponente: string, eventName: string, typ: string = 'METHOD', info: string = '') {
        if (environment.debug === true) {
            console.log(this.getTimeStringHMS() + ' - ' + typ + ': ' + komponente + '.' + eventName + (info === '' ? '' : ' - ' + info))
        }
    }
}
