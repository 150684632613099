/**
 * Datentyp für die Files
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2020-10-29
 *
 */

export class FileDocument {

    public id = -1
    public filename = ''
    // tslint:disable-next-line: variable-name
    public first_view = null // Datum und Uhrzeit, zu der ein Benutzer die Datei geladen hat
}
