/**
 * Komponente für die Passwortänderung
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2019-09-03
 *
 */



import { environment } from '../../environments/environment';

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService, LanguageService, UserService } from '../_services/_index';

import { HttpData, Result, User } from '../_models/_index';



@Component({
    selector: 'app-password',
    templateUrl: 'password.component.html',
    styleUrls: ['password.component.css']
})
export class PasswordComponent implements AfterViewInit {



    @ViewChild('passwordOneElement') passwordOneElement: ElementRef;
    @ViewChild('passwordTwoElement') passwordTwoElement: ElementRef;
    @ViewChild('passwordThreeElement') passwordThreeElement: ElementRef;

    public passwordOne = ''; // Aktuelles Passwort
    public passwordTwo = ''; // Neues Passwort
    public passwordThree = ''; // Neues Passwort (Wiederholung)

    public alert = ''; // Container für Rückmeldungen an den User
    public success = false; // Passwortänderung erfolgreich oder nicht
    public waitingForServer = false; // Flag, wenn die Serveranfrage auf Antwort wartet.



    public constructor(
        private router: Router,
        private httpService: HttpService,
        private languageService: LanguageService,
        private userService: UserService) { }



    public ngAfterViewInit() {
        this.passwordOneElement.nativeElement.focus();
    }



    public passwordChange() {
        if (this.waitingForServer === false) {

            this.waitingForServer = true;
            this.alert = '';

            if ((this.passwordOne !== '') && (this.passwordTwo !== '') && (this.passwordThree !== '')) {

                if (this.passwordTwo !== this.passwordThree) {
                    this.passwordThreeElement.nativeElement.focus();
                    this.alert = this.languageService.get('de', 'PASSWORD_CHANGING_ERROR_REPEAT');
                    this.waitingForServer = false;
                } else {

                    // Passwortänderung auf dem Server durchführen

                    this.passwordOneElement.nativeElement.focus();

                    const user = this.userService.getUser();
                    const httpData = new HttpData(user, 'password_change');
                    httpData.data['password_old'] = this.passwordOne;
                    httpData.data['password_new'] = this.passwordTwo;
                    httpData.data['errormsg'] = this.languageService.get('de', 'PASSWORD_CHANGING_ERROR'); // Fehlermeldung für "Aktuelles Passwort falsch" (kann nur der Server prüfen)

                    this.httpService.getDataByPost(httpData, 'password.passwordChange').subscribe(

                        (result: Result) => {
                            if (result.status === 'OK') {
                                this.passwordOne = '';
                                this.passwordTwo = '';
                                this.passwordThree = '';
                                this.success = true;
                                this.waitingForServer = false;
                            } else {
                                this.alert = this.languageService.get('de', result.data);
                                this.waitingForServer = false;
                            }
                        },

                        (error: Result) => {
                            this.alert = this.languageService.get('de', 'SERVER_NOT_AVAILABLE');
                            this.waitingForServer = false;
                        }
                    );
                }
            } else {
                this.alert = this.languageService.get('de', 'PASSWORD_CHANGING_ERROR_FIELDS');
                this.waitingForServer = false;
            }
        }
    }

    public close() {
        // Zurück zur Tagessicht.
        // 2020-02-01 - Funktioniert nicht auf iOS - this.router.navigate(['/'], { replaceUrl: true, skipLocationChange: true });
        window.location.reload();
    }
}
