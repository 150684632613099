/**
 * Komponente für das Funktionsmenü
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-02-01
 * Revision: 2019-10-08
 *
 */

import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { environment } from '../../environments/environment'
import { DisplayService, FilesService, HttpService, StorageService, ToolsService, UserService } from '../_services/_index'
import { HomeComponent } from '../home/home.component'

declare var $: any // jQuery

@Component({
  selector: 'app-functions',
  templateUrl: 'functions.component.html',
  styleUrls: ['functions.component.css'],
})
export class FunctionsComponent implements OnInit {

  @Input() public homeComponent: HomeComponent // Referenz auf die Home-Komponente
  @Input() public title: string // Text für den Titel
  @Input() public displayFileDialog: false // Anzeige Button für den Mediafile-Dialog

  public isDebug = false // Wird aus dem ENVIRONMENT übernommen. Anzeige von DEBUGinformationen
  public debuginfos = '' // Wenn isDEBUG, dann werden diese Infos angezeigt

  public projektwahl = false // Projektwahl möglich oder nicht

  public isOnline = true // System Online (TRUE) oder nicht (FALSE)

  public hasProject = false // Hat der User ein aktives Projekt?
  public isMesseuser = false // Ist der User ein Messe-User?

  public isBlocked = false // True, denn das Projekt blockiert ist, also nichts mehr geändert werden darf

  public isSubmenu = false // Hamburgermenue (FALSE) oder Zurückpfeil (TRUE)

  public urlLeitfaedenMessejob = '' // Die URL zur Seite "Leitfäden Messejob" aus dem Environment
  public urlHilfeFAQ = '' // Die URL zur Hilfe/FAQ-Seite aus dem Environment

  public filesIconVisible = false // Icon für das Datei-Modal sichtbar
  public filesIconAnimated = true // Icon für das Datei-Modal blink, weil noch Dokumente ungelesen sind

  public faFileAlt = faFileAlt // Das Dokument-Symbol oben rechts

  // LIFECYCLE -----------------------------------------------------------------------------------------------

  public constructor(
    private router: Router,
    private displayService: DisplayService,
    private httpService: HttpService,
    private filesService: FilesService,
    private storageService: StorageService,
    private toolsService: ToolsService,
    private userService: UserService) { }

  public ngOnInit() {

    // Der User
    const user = this.userService.getUser()
    this.hasProject = (user.currentProject.id !== -1)
    this.isMesseuser = (user.messeuser === 1)

    // Wenn der Titel noch leer ist, korrigieren
    if (this.title === '') { this.title = ' ' } // ACHTUNG: "Spezialleerzeichen" Alt+0160

    // Event "SystemOnline" aus dem "httpService" für die ONLINE/OFFLINE-Detection abonnieren
    this.httpService.SystemOnline.subscribe((systemOnline) => {
      this.isOnline = systemOnline
      if (this.isOnline) {
        this.filesService.fetchFilesList()
      }
      if (this.isDebug === true) { console.log('SYSTEM: ' + (this.isOnline ? 'ONLINE' : 'OFFLINE')) }
    })

    // Events für die Dateianzeige abonnieren
    this.filesService.filesCount.subscribe((filesCount) => {
      this.filesIconVisible = (this.displayFileDialog && (filesCount > 0))
    })
    this.filesService.filesCountUnread.subscribe((filesCountUnread) => {
      this.filesIconAnimated = (this.filesIconVisible && (filesCountUnread > 0))
    })

    // isDebugging?
    this.isDebug = (environment.debug === true)
    if ((this.isDebug === true) && (user !== null)) {
      this.debuginfos = environment.version
      this.debuginfos += ' / User #' + user.id
      this.debuginfos += ' / Project #' + user.currentProject.id
      this.debuginfos += ' / ' + (user.messeuser === 1 ? 'Messeuser' : 'normaler User')
      this.debuginfos += ' / User Mail: ' + (user.email !== '' ? user.email : '*** keine Mailadresse ***')
      this.debuginfos += ' / Client Type: ' + (this.storageService.get('ClientType', 'unknown') === 1 ? 'appTitan' : 'Web')
      this.debuginfos += ' / ' + (user.currentProject.messeprojekt === 1 ? 'Messeprojekt' : 'Normales Projekt')
      this.debuginfos += ' / Projekt ' + (user.currentProject.inaktiv === 1 ? 'inaktiv' : 'aktiv')
      this.debuginfos += ' / ' + (user.currentProject.email !== '' ? user.currentProject.email : '*** keine abweichende Mailadresse ***')
      this.debuginfos += ' / Mail Template: ' + (user.currentProject.template !== '' ? user.currentProject.template : 'Standard')
    }

    // Projektauswahl möglich?
    this.projektwahl = ((user === null) ? false : this.projektwahl = (user.projects.length > 1))

    // System Online?
    this.isOnline = (this.storageService.get('ServerStatus', 'OFFLINE') === 'ONLINE')

    // Projekt inaktiv?
    this.isBlocked = ((user !== null) && (user.currentProject !== null) && (user.currentProject.inaktiv === 1))

    // URLs aus dem Environment
    this.urlLeitfaedenMessejob = environment.urlLeitfaedenMessejob
    this.urlHilfeFAQ = environment.urlHilfeFAQ

    // Aktualisierung der Mediafiles antriggern, damit die Komponente auf jeden Fall die aktuellen Werte hat.
    this.filesService.fetchFilesList('filesIconComponent.ngOnInit')
  }

  // METHODS ------------------------------------------------------------------------------------------------

  // Schließt das Menue
  public closeMenu() {
    $('#collapsingMenuBlocker').css('display', 'none')
    if ($('.navbar-collapse').hasClass('show')) {
      $('.navbar-toggler').trigger('click')
      $('#collapsingMenuBlocker').css('display', 'none')
    }
  }

  // Klappt im Wechsel das Menue auf oder zu
  public curtainToggle() {
    if ($('#collapsingMenuBlocker').css('display') === 'block') {
      $('#collapsingMenuBlocker').css('display', 'none')
    } else {
      $('#collapsingMenuBlocker').css('display', 'block')
    }
  }

  // Wechselt zur Tagesansicht von Heute
  public heute() {
    this.closeMenu()
    const user = this.userService.getUser()
    user.nav_date = this.toolsService.getDateString()
    user.nav_today = 1
    this.userService.setUser(user)
    this.storageService.remove('lastTag')
    this.storageService.remove('lastMonat')
    this.router.navigate(['/'], { replaceUrl: true, skipLocationChange: true })
    if (this.isDebug) { console.log('FUNCTION: SCREEN REFRESH') }
    this.displayService.sendRefreshView()
  }

  // Speziell für Tests
  public debug() {
    console.log(`Nothing to do...`)
    // this.filesService.fetchFilesList('function.debug')
  }

  // Führt das LOGOUT durch - Code s. auch "home.component.midnightRefresh"
  public logout() {
    this.closeMenu()
    this.storageService.set('SESSION_STOPPED', 'TRUE')
    this.displayService.sendTimeStopper()
    this.displayService.sendRefreshView()
    this.httpService.serverStatusCheckerEnd()
    this.storageService.clear()
    if (this.isDebug) { console.log('FUNCTION: SCREEN REFRESH') }
    this.router.navigate(['/login'], { replaceUrl: true, skipLocationChange: true })
  }

  // Öffnet den FileDocument-Dialog
  public openFilesDialogModal() {
    this.filesService.openModal('FilesDialog')
  }

  // Ruft den Dialog "Passwortänderung" auf
  public passwortaenderung() {
    this.closeMenu()
    this.router.navigate(['password'], { replaceUrl: true, skipLocationChange: true })
  }

  // Ruft den Dialog "Projektauswahl" auf (der Menuepunkt ist deaktiviert, wenn kein Projektwechsel möglich ist)
  public projektwechsel() {
    this.closeMenu()
    this.router.navigate(['projects'], { replaceUrl: true, skipLocationChange: true })
  }

}
