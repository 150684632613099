/**
 * The AppComponent
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2017-12-01
 * Revision: 2019-12-12
 *
 */

import { AfterViewInit, Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../environments/environment'
import { StorageService, ToolsService, UserService } from './_services/_index'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit {

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private userService: UserService,
    private storageService: StorageService,
    private toolsService: ToolsService) {

    this.toolsService.debugInfo('AppComponent', 'constructor')

    // Seitentitel setzen
    let title = 'myTime - Lorenz Personal Zeiterfassung' // Mail vom 05.11.2019 - 13:11
    if (environment.version !== 'LIVE') { title += ' - ' + environment.version }
    this.titleService.setTitle(title)

    // Client-Typ ermitteln und ins LocalStorage schreiben - 1 => appTITAN; ALLES ANDERE => Webbrowser
    this.route.queryParamMap.subscribe((queryParams) => {
      let clientType = 0
      let clientTypeText = 'Webbrowser'
      if (queryParams.get('apptitan') === '') { // "?apptitan" wird von Client mitgeliefert!
        clientType = 1
        clientTypeText = 'appTitan'
      }

      // For testing purposes...
      // clientType = 1;
      // clientTypeText = 'appTitan';

      this.storageService.set('ClientType', clientType.toString())
      this.storageService.set('ClientTypeText', clientTypeText)

      const user = this.userService.getUser()
      user.client = clientType
      this.userService.setUser(user)
    })
  }

  public ngAfterViewInit() {

    this.toolsService.debugInfo('AppComponent', 'ngAfterViewInit', 'LIFECYCLE')

    if (environment.debug === true) {
      document.getElementById('body').classList.add('fd_bootstrap')
    }
  }
}
