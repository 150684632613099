/**
 * Application Modules
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-01-17
 *
 */

import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { PdfViewerModule } from 'ng2-pdf-viewer'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { FilesDialogComponent } from './files-dialog/files-dialog.component'
import { FilesViewerComponent } from './files-viewer/files-viewer.component'
import { FunctionsComponent } from './functions/functions.component'
import { HomeDayComponent } from './home-day/home-day.component'
import { HomeMonthComponent } from './home-month/home-month.component'
import { HomeYearComponent } from './home-year/home-year.component'
import { HomeComponent } from './home/home.component'
import { LoginComponent } from './login/login.component'
import { PasswordComponent } from './password/password.component'
import { ProjectsComponent } from './projects/projects.component'
import { AccessGuard, ProjectGuard } from './_guards/_index'
import { DisplayService, FilesService, HttpService, LanguageService, StorageService, ToolsService, UserService } from './_services/_index'

@NgModule({

  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FontAwesomeModule, // https://www.npmjs.com/package/@fortawesome/angular-fontawesome/v/0.3.0
    FormsModule,
    HttpClientModule,
    NgbModule, // https://ng-bootstrap.github.io/#/components/modal/examples
    PdfViewerModule, // https://www.npmjs.com/package/ng2-pdf-viewer
  ],

  declarations: [
    AppComponent,
    FilesDialogComponent,
    FilesViewerComponent,
    FunctionsComponent,
    HomeComponent,
    HomeDayComponent,
    HomeMonthComponent,
    HomeYearComponent,
    LoginComponent,
    PasswordComponent,
    ProjectsComponent,
  ],

  providers: [
    AccessGuard,
    ProjectGuard,

    DisplayService,
    FilesService,
    HttpService,
    LanguageService,
    StorageService,
    ToolsService,
    UserService,
  ],

  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
