/*
 * Service für das HTTPDATA-Objekt und die Kommunikation via HTTP
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-01-16
 * Revision: 2019-12-12
 *
 */

import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { EventEmitter, Injectable, Output } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { HttpData, Result } from '../_models/_index'
import { DisplayService } from '../_services/display.service'
import { StorageService } from '../_services/storage.service'
import { ToolsService } from '../_services/tools.service'

@Injectable()
export class HttpService {

    @Output() public SystemOnline: EventEmitter<boolean> = new EventEmitter() // Sendet TRUE, wenn das System Zugriff auf den Server hat, sonst FALSE

    public timerServerPing: any = null // Timer (Intervall) für den Server-Ping

    constructor(
        private displayService: DisplayService,
        private httpClient: HttpClient,
        private storageService: StorageService,
        private toolsService: ToolsService) { }

    // METHODEN FÜR DIE HTTP(S)-KOMMUNIKATION -----------------------------------------------------------------

    // Ruf per POST ein Result-Objekt vom Server ab ("caller" wird als Info beim Debugging benutzt).
    public getDataByPost(httpData: HttpData, caller = '', options = {}): Observable<Result> {

        const url = environment.urlRest
        const data2send = this.prepareData(httpData)

        if (environment.debug === true) {
            console.log('DATA REQUEST - TOKEN   => ' + httpData.getToken())
            console.log('DATA REQUEST - DATE    => ' + httpData.data['_clientdate_'])
            console.log('DATA REQUEST - CALLER  => ' + caller)
            console.log('DATA REQUEST - COMMAND => ' + httpData.command)
            console.log('DATA REQUEST - URL     => ' + url)
            console.log('DATA REQUEST - DATA    => ' + data2send)
        }

        // ANM: Server-Fehler werden hier in ein Result-Objekt umgewandelt.
        return this.httpClient.post(url, data2send, options)
            .pipe(
                map(
                    (response: Result) => {
                        if ((response.status === 'ERROR') && (response.data === 'INVALID TOKEN')) {
                            this.displayService.sendCancelSession() // SESSION BEENDEN, WEIL TOKEN UNGÜLTIG
                        }
                        return response
                    },
                    (error: Result) => {

                        // REST Error
                        this.toolsService.debugInfo('http.service', 'getDataByPost', 'ERROR', error.data)
                        return error
                    },
                ),
                catchError((error: HttpErrorResponse) => {
                    const result = new Result()
                    result.status = 'ERROR'
                    result.data = error.message
                    this.toolsService.debugInfo('http.service', 'getDataByPost', 'ERROR', error.message)
                    this.storageService.set('ServerStatus', 'OFFLINE')
                    this.SystemOnline.emit(false)
                    return throwError(result)
                }),
            )
    }

    // OBSOLET Ruft ein PDF vom Server ab ("caller" wird beim Debugging als Info ausgegeben).
    // public fetchPostDataAsPDF(httpData: HttpData, caller = ''): any {

    //     const url = environment.urlRest
    //     const data2send = this.getPostData(httpData)

    //     if (environment.debug === true) {
    //         const call = (httpData.command.toUpperCase() + ((caller === '') ? '' : '/') + caller)
    //         console.log('PDF REQUEST - TOKEN ' + httpData.getToken())
    //         console.log('PDF REQUEST - URL (' + caller + '): ' + url)
    //     }

    //     return this.httpClient.post(url, data2send, { responseType: 'blob' }).pipe(
    //         map(
    //             (response: Blob) => {
    //                 return new Blob([response], { type: 'application/pdf' })
    //             },
    //             (error: Result) => {
    //                 this.toolsService.debugInfo('http.service', 'fetchPostDataAsPDF', 'ERROR', error.data)
    //                 return error
    //             },
    //         ),
    //         catchError((error: HttpErrorResponse) => {
    //             const result = new Result()
    //             result.status = 'ERROR'
    //             result.data = error.message
    //             this.toolsService.debugInfo('http.service', 'getDataByPost', 'ERROR', error.message)
    //             this.storageService.set('ServerStatus', 'OFFLINE')
    //             this.SystemOnline.emit(false)
    //             return throwError(result)
    //         }),
    //     )
    // }

    // Liefert die Daten für die Übermittlung an den Server.
    public prepareData(httpData: HttpData): string {
        let data = JSON.stringify(httpData)
        data = this.toolsService.maskHTML(data)
        data = encodeURIComponent(data)
        data = window.btoa(data)
        return data
    }

    // METHODEN FÜR DIE ONLINE/OFFLINE PRÜFUNG ----------------------------------------------------------------

    // WICHTIG: Diese Methoden prüfen NUR für das AKTIVE DEVICE, ob der Server korrekt verfügbar ist!
    //          Sollte IRGENDETWAS nicht stimmen, schaltet das System auf OFFLINE.

    // Server-Status-Checker starten
    public serverStatusCheckerStart() {

        this.serverStatusCheckerEnd()

        this.getServerStatus()

        this.timerServerPing = setInterval(() => {
            if (environment.debug === true) { console.log('TIMER: ServerStatusChecker TRIGGER (' + this.toolsService.getTimeStringHMS() + ' - TimerID #' + this.timerServerPing + ')') }
            this.getServerStatus()
        }, environment.pingIntervall * 1000) // Millisekunden

        if (environment.debug === true) { console.log('TIMER: ServerStatusChecker START (Intervall ' + environment.pingIntervall + ' Sekunden - TimerID #' + this.timerServerPing + ')') }
    }

    // Server-Status-Checker beenden
    public serverStatusCheckerEnd() {
        if (this.timerServerPing !== null) {
            if (environment.debug === true) { console.log('TIMER: ServerStatusChecker END (' + this.toolsService.getTimeStringHMS() + ' - TimerID #' + this.timerServerPing + ')') }
            clearInterval(this.timerServerPing)
            this.timerServerPing = null
        }
    }

    // Prüft, ob das (aktive) Device den Server erreichen kann oder nicht, schreibt den letzten ermittelten Status ins LocalStorage und sendet einen Event
    public getServerStatus() {
        const user = this.storageService.get('User', null) // Muss hier so sein, sonst gibt's einen Zirkelbezug...
        if (user === null) {
            this.serverStatusCheckerEnd()
        } else {
            const httpData = new HttpData(user, 'ping')
            this.getDataByPost(httpData, 'HttpService.getServerStatus').subscribe(
                (result: Result) => {
                    if (result.status === 'OK') {
                        this.storageService.set('ServerStatus', 'ONLINE')
                        this.SystemOnline.emit(true)
                    } else {
                        // Anm.: Ungültige Token werden vorher verarbeitet!
                        this.toolsService.debugInfo('http.service', 'getServerStatus', 'ERROR', result.data)
                        this.storageService.set('ServerStatus', 'OFFLINE')
                        this.SystemOnline.emit(false)
                    }
                },
                (error: Result) => {
                    this.toolsService.debugInfo('http.service', 'getServerStatus', 'ERROR', error.data)
                    this.storageService.set('ServerStatus', 'OFFLINE')
                    this.SystemOnline.emit(false)
                },
            )
        }
    }

    // Senden System OFFLINE in den Äther
    public sendSystemOffline() {
        this.storageService.set('ServerStatus', 'OFFLINE')
        this.SystemOnline.emit(false)
    }

    // Liefert TRUE oder False, wenn der ServerStatusChecker läuft oder nicht
    public serverStatusCheckerRunning() {
        return (this.timerServerPing !== null)
    }
}
