/**
 * Guard für den Seitenzugriff
 *
 * Nur wer hier vorbeikommt darf ins System!
 *
 * Das im Rahmen des LOGIN-Vorgangs kommunizierte Zugangstoken wird im User-Objekt und im LocalStorage
 * gespeichert und bei jeder Kommunikation mit dem Server mitgeschickt. Weichen das lokale und das auf
 * dem Server gespeicherte Token voneinander ab, wird eine Meldung ausgegeben und der User augeloggt.
 *
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-01-17
 * Revision: 2019-10-28
 *
 */



import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { HttpService, StorageService, ToolsService, UserService } from '../_services/_index';



@Injectable()
export class AccessGuard implements CanActivate {



    public constructor(
        public httpService: HttpService,
        public storageService: StorageService,
        public toolsService: ToolsService,
        public userService: UserService,
        public router: Router) { }



    public canActivate() {

        this.toolsService.debugInfo('AccessGuard', 'canActivate');

        let access = false; // GRUNDSÄTZLICH ERSTMAL KEIN ZUGRIFF

        const user = this.userService.getUser();

        const auth_user = (user === null ? '@@@' : user.token);
        const auth_localStorage = this.storageService.get('Salt', '***');

        if (auth_user.toString() === auth_localStorage.toString()) {

            access = true;
        }

        // Ein MesseUSER kommt nur vorbei, wenn er sich "Heute" eingeloggt hat, egal in welchem Projekt.
        if ((user.messeuser === 1) && (user.logdate !== this.toolsService.getDateString())) {
            access = false;
        }

        if (access === false) {

            this.router.navigate(['/login'], { replaceUrl: true, skipLocationChange: true });

        } else {

            // Online-Checker bei Bedarf starten
            if (this.httpService.serverStatusCheckerRunning() === false) {
                this.httpService.serverStatusCheckerStart();
            }

            // Kein Projekt festlegt?
            if (user.currentProject.id === -1) {
                this.router.navigate(['/projects'], { replaceUrl: true, skipLocationChange: true });
            }

        }

        if (environment.debug === true) {
            console.log('ACCESS GUARD - ' + access + ' / auth_user=' + auth_user + ' / auth_localStorage=' + auth_localStorage);
        }

        return access;
    }
}
