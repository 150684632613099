/*
 * Service für die Mehrsprachigkeit
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-05-09
 *
 */



import { Injectable } from '@angular/core';



declare var $: any;



@Injectable()
export class LanguageService {



    public constructor() { }



    // Liefert den Textidentifiert 'text' in Sprache 'language'
    public get(language = 'de', text = ''): string {

        const texte = {

            'ACCOUNT_NOT_AVAILABLE': {
                'de': 'Benutzerkonto noch nicht verfügbar',
                'en': 'User account not yet available',
            },

            'DB_NOT_AVAILABLE': {
                'de': 'Datenbank nicht erreichbar',
                'en': 'Database not available',
            },

            'LOGIN_DENIED': {
                'de': 'Zugangsdaten fehlerhaft',
                'en': 'Login denied',
            },

            'USERNAME_MISSING': {
                'de': 'Benutzername fehlt',
                'en': 'Username missing',
            },

            'PASSWORD_CHANGING_ERROR': {
                'de': 'Das aktuelles Passwort stimmt nicht',
                'en': 'Current password incorrect',
            },

            'PASSWORD_CHANGING_ERROR_FIELDS': {
                'de': 'Füllen Sie bitte alle drei Felder aus',
                'en': 'Please fill in all three fields',
            },

            'PASSWORD_CHANGING_ERROR_REPEAT': {
                'de': 'Die neuen Passwörter stimmen nicht überein',
                'en': 'New passwords do not match',
            },

            'PASSWORD_MISSING': {
                'de': 'Passwort fehlt',
                'en': 'Password missing',
            },

            'PASSWORD_NEW_SENT': {
                'de': 'Das neue Passwort wurde versendet',
                'en': 'The new password has been sent',
            },

            'PARAMETER_ERROR': {
                'de': 'Ungültige Anfrage',
                'en': 'Invalid request',
            },

            'SERVER_NOT_AVAILABLE': {
                'de': 'Server nicht erreichbar',
                'en': 'Server not available',
            },

            'SESSION_CANCELED': {
                'de': 'Sitzung abgelaufen - Bitte melden Sie sich neu an',
                'en': 'Session timed out - Please log in again',
            },

            'SMTP_ERROR': {
                'de': 'Verbindung zum Mail-Server fehlgeschlagen',
                'en': 'Connection to mail server failed',
            },

            'STATE_FT': {
                'de': 'Feiertag',
                'en': 'Holiday',
            },

            'STATE_GZ': {
                'de': 'Gleitzeit',
                'en': 'Flexible working hours',
            },

            'STATE_K': {
                'de': 'Krank',
                'en': 'Illness',
            },

            'STATE_KUG': {
                'de': 'Kurzarbeitergeld',
                'en': 'Kurzarbeitergeld',
            },

            'STATE_U': {
                'de': 'Urlaub',
                'en': 'Vacation',
            },

            'STATE_SU': {
                'de': 'Sonderurlaub',
                'en': 'Special leave',
            },

            'UNKNOWN_USER': {
                'de': 'Benutzer existiert nicht, bitte wenden Sie sich an Ihren Ansprechpartner bei Lorenz Personal',
                'en': 'Unknown user',
            },
        };

        let result = text;

        $.each(texte, function (index, value) {
            if (text === index) {
                if (value.hasOwnProperty(language)) {
                    result = value[language];
                }
            }
        });

        return result;
    }
}
