/**
 * Komponente für die Anzeige der Arbeitszeitdaten - View "Monat"
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-03-01
 * Letzte Revision: 2021-02-03
 *
 */

import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { environment } from '../../environments/environment'
import { Display, HttpData, Result } from '../_models/_index'
import { DisplayService, HttpService, LanguageService, StorageService, ToolsService, UserService } from '../_services/_index'

declare var $: any
declare var scrollmasterMove: any
declare var window: any

@Component({
  selector: 'app-home-month',
  templateUrl: 'home-month.component.html',
  styleUrls: ['home-month.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class HomeMonthComponent implements OnChanges, AfterViewInit {

  @Input() public display: Display // DER AKTUELLE MONAT
  @Input() public section: number // Die aktuelle Sektion

  public debug = environment.debug // Anzeige von DEBUGinformationen
  public debuginfos1 = '' // Wenn DEBUG, dann werden diese Infos angezeigt

  public formattedDate = '' // Formatierte Datumsanzeige für den Monat
  public tableHeight = 0 // Höhe der Tabelle in px
  public fontSizeTable = 10.0 // Schriftgröße für die Tabelle in px
  public fontSizeButtons = 14.0 // Schriftgröße für die Buttonbeschriftung in px

  public isMesseprojekt = false // Flag, ob das aktuelle Projet eine "Messeprojekt" ist
  public isBlocked = false // Monate in normalen Projekten sind nach der Freigabe blockiert.
  public isInactive = false // Flag, ob das aktuelle Projekt inaktiv ist (Inaktive Projekte sind auf jeden Fall auch blockiert)
  public isAppTitan = false // Flag, ob der aktuelle Client der appTitan ist

  public canAbrufen = 0 // Abruf-Button: 0 = verfügbar; 1 = deaktiviert; 2 = ausgeblendet
  public canLorenz = 0 // Freigabe-/Versenden-Button: 0 = verfügbar; 1 = deaktiviert; 2 = ausgeblendet

  public transfer_lorenz = '' // Formatiertes Datum für die Buttonbeschriftung; Tag, an dem dem die Monatsübersicht zuletzt zu Lorenz übertragen wurde

  public clientType = 0 // Typ Abrufclient (1 => appTITAN; alles andere => Webbrowser)

  public downloadPDF = '' // Downloadlink zur Zeitenübersicht auf dem Server
  public mailPDF = '' // Fertiger 'mailto'-Link

  public lorenzButtonText = '' // Text für den "Freigeben-" (Normales Projekt) oder "Versenden-" (Messeprojekt) Button

  // LIFECYCLE -----------------------------------------------------------------------------------------------

  public constructor(
    private displayService: DisplayService,
    private httpService: HttpService,
    private languageService: LanguageService,
    private toolsService: ToolsService,
    private userService: UserService,
  ) { }

  public ngOnChanges() {

    this.toolsService.debugInfo('home-month', 'ngOnChanges', 'LIFECYCLE')

    this.display = this.displayService.getDisplay()

    const user = this.userService.getUser()

    this.formattedDate = this.toolsService.formatDate(this.display.date)
    this.transfer_lorenz = this.toolsService.getDatumString(new Date(this.display.view.transfer_lorenz)) // Für die Anzeige
    this.clientType = user.client

    this.refreshMonthInfos()

    // "Nicht-App-Titan"-Link zum Tätigkeitsbericht/Zeiterfassung
    this.downloadPDF = environment.urlDownloads + 'download.php?file=' + this.display.view.checksum

    if (this.clientType === 1) { // appTITAN Mailversand

      // Mailversand
      const betreff = user.currentProject.projectname + ' - Tätigkeitsnachweis%20' + this.formattedDate.replace(' ', '%20')
      const nachricht = 'Über%20diesen%20Link%20können%20Sie%20den%20angeforderten%20Tätigkeitsnachweis%20herunterladen.%0D%0A%0D%0A' + this.downloadPDF
      this.mailPDF = 'mailto:?subject=' + betreff + '&body=' + nachricht
    }

    this.refreshDebugInfo()
  }

  public ngAfterViewInit() {

    this.toolsService.debugInfo('home-month', 'ngAfterViewInit', 'LIFECYCLE')

    this.display = this.displayService.getDisplay()

    // Schriftgröße in der Tabelle anpassen, damit alles auf die Seite passt

    const slideHeight = $('.fp-section.active').outerHeight(true)
    const navHeight = $('nav').outerHeight(true)
    const dateHeight = $('#date-' + this.display.date).outerHeight(true)
    const buttonHeight = ($('#button-' + this.display.date).length === 0) ? 0 : $('#button-' + this.display.date).outerHeight(true)
    const currentTableHeight = $('#mainTable-' + this.display.date).outerHeight(true)
    const currentTableFontsize = parseFloat($('#mainTable-' + this.display.date).css('font-size'))
    const finalTableHeight = slideHeight - navHeight - dateHeight - buttonHeight
    const scale = finalTableHeight / currentTableHeight

    this.fontSizeTable = currentTableFontsize * scale

    this.toolsService.debugInfo('home-month', 'ngAfterViewInit', 'LIFECYCLE', 'font-size: ' + this.fontSizeTable)
  }

  // METHODS ------------------------------------------------------------------------------------------------

  // Wechselt zur Tagesansicht für Tag "datum"
  public goToTag(datum: string) {
    if (environment.debug === true) { console.log('JUMP TO: ' + datum) }
    window.scrollmaster.zone.run(() => { window.scrollmaster.wipe(datum, this.section, 1, this.section, 0) })
    scrollmasterMove(this.section, 0)
  }

  // Löscht die Daten von Tag "datum"
  public delete(datum: string) {

    if (confirm('Löschen der Daten vom ' + this.toolsService.formatDate(datum))) {

      const user = this.userService.getUser()
      const httpData = new HttpData(user, 'timedelete')
      httpData.data['id_users'] = user.id
      httpData.data['id_projects'] = user.currentProject.id
      httpData.data['date'] = datum
      this.httpService.getDataByPost(httpData, 'home-month.delete').subscribe(

        (result: Result) => {
          window.location.reload()
        },

        (error: Result) => {
          window.location.reload()
        },

      )
      if (this.debug) {
        console.log('Daten gelöscht: User #' + httpData.data['id_users'] + ' / Projekt #' + httpData.data['id_projects'] + ' / Datum ' + httpData.data['date'])
      }
    }
  }

  // Den Tätigkeitsbericht anzeigen (nur "Nicht-appTitan")
  public activity_report_view(datum: string) {

    // Den Abruf protokollieren.
    this.display = this.displayService.getDisplay()
    this.display.view.transfer_user = this.toolsService.getDateString()
    this.display = this.displayService.setDisplay(this.display)

    // Infos aktualisieren.
    this.refreshMonthInfos()
    this.refreshDebugInfo()

    if (this.debug === true) {
      console.log('VIEW Activity Report: ' + datum)
    }
  }

  // Den Tätigkeitsbericht versenden (Messeprojekt) oder freigeben (Nicht-Messeprojekt)
  public activity_report_send(datum: string) {

    this.display = this.displayService.getDisplay()

    if (this.debug) { console.log('SEND Activity Report: ' + datum) }

    const user = this.userService.getUser()
    const httpData = new HttpData(this.userService.getUser(), 'report')
    httpData.data['datum'] = datum
    httpData.data['projekt'] = user.currentProject
    this.httpService.getDataByPost(httpData, 'home-month.activity_report_send').subscribe()
    this.display.view.transfer_lorenz = this.toolsService.getDateString()
    this.transfer_lorenz = this.toolsService.getDatumString(new Date(this.display.view.transfer_lorenz))
    this.display = this.displayService.setDisplay(this.display)

    this.refreshMonthInfos()
    this.refreshDebugInfo()

    // Abschlussmeldung
    if (this.isMesseprojekt === false) {
      alert('Die Daten wurden freigegeben!')
    } else {
      alert('Die Daten wurden versendet!')
      this.isBlocked = true
    }
  }

  // Aktualisiert die Dialog-Infos
  private refreshMonthInfos() {
    const user = this.userService.getUser()

    this.isMesseprojekt = (user.currentProject.messeprojekt === 1)
    this.isInactive = (user.currentProject.inaktiv === 1)
    this.lorenzButtonText = 'Stundennachweis ' + (this.isMesseprojekt === true ? 'versenden' : (this.display.view.transfer_lorenz === '' ? 'freigeben' : 'freigegeben'))
    this.isAppTitan = (user.client.toString() === '1')

    // Monat blockiert?
    if (this.isInactive === true) {
      this.isBlocked = true // Inaktive Projekte sind auf jeden Fall auch blockiert
    } else if (this.isMesseprojekt === false) { // "Nicht-Messeprojekte", die freigegeben sind auch
      this.isBlocked = (this.display.view.transfer_lorenz !== '')
    }

    // Die Buttons - 0 = verfügbar; 1 = deaktiviert; 2 = ausgeblendet
    this.canAbrufen = 0
    this.canLorenz = 0

    // Daten nicht i.O => immer blocken
    if (this.display.view.checkOk.toString() !== '1') {

      this.canAbrufen = 1
      this.canLorenz = 1

    } else {

      // Daten i.O aber...
      if (this.isMesseprojekt === true) {

        // Messeprojekt -> Kann immer alles
        this.canAbrufen = 0
        this.canLorenz = 0

      } else {

        // Kein Messeprojekt -> Freigabe erst nach 1x download
        if (this.display.view.transfer_user === '') {
          this.canLorenz = 1
        }

        // Kein Messeprojekt -> wenn die Freigabe erfolgt ist, wir der Monat blockiert
        if (this.display.view.transfer_lorenz !== '') {
          this.canLorenz = 1
        }

      }
    }

    // appTitan (1) - Wenn der User keine E-Mail hat, kann er auch nichts für sich selbst abrufen.
    if ((user.client === 1) && (user.email.trim() === '')) {
      this.canAbrufen = 1
    }

    // Bei inaktiven Projekte sind immmer beide Buttons inaktiv.
    if (this.isInactive === true) {
      this.canAbrufen = 2
      this.canLorenz = 2
    }
  }

  // Erfrischt die Debug-Infos
  private refreshDebugInfo() {
    if (this.debug) {
      const user = this.userService.getUser()

      this.debuginfos1 = 'Datum ' + user.nav_date
      this.debuginfos1 += ' / Transfer USER: ' + (this.display.view.transfer_user === '' ? 'NOCH NICHT' : this.display.view.transfer_user)
      this.debuginfos1 += ' / Transfer LORENZ: ' + (this.display.view.transfer_lorenz === '' ? 'NOCH NICHT' : this.display.view.transfer_lorenz)
      this.debuginfos1 += ' / Abruf: ' + (this.canAbrufen === 0 ? 'JA' : (this.canAbrufen === 1 ? 'BLOCKIERT' : 'AUSGEBLENDET'))
      this.debuginfos1 += ' / Freigabe: ' + (this.canLorenz === 0 ? 'JA' : (this.canLorenz === 1 ? 'BLOCKIERT' : 'AUSGEBLENDET'))
      this.debuginfos1 += ' / Check: ' + (this.display.view.checkOk.toString() === '1' ? 'OK' : 'NICHT OK')
    }
  }

}
