<div id="home-day">

    <div class="col-12 mt-3 text-center h3" [attr.title]="debug ? debuginfos1 + '&#10;' + debuginfos2 + '&#10;' : null" [ngClass]="{'text-muted': isBlocked === true}">
        {{formattedDate}}
    </div>

    <div class="p-1">

        <!-- DISPLAY: Tagesstatus "Zeiterfassung" -->

        <form *ngIf="isSpecialDay === false">

            <table class="table fd-borderless">

                <tr [ngClass]="{'text-muted': (isBlocked === true) || (isNeu === true)}">
                    <td class="w-50 pl-1 pr-1 align-middle text-right">Arbeitsbeginn</td>
                    <td class="w-50 pl-1 pr-1 align-middle text-left">
                        <input type="text" id="begin-{{display.date}}" name="begin-{{display.date}}"
                            autocomplete="off"
                            class="form-control input"
                            [attr.disabled]="editMode ? null : 'disabled'"
                            [ngClass]="{'disabled': editMode === false, 'text-muted': (isBlocked === true) || (isNeu === true)}"
                            [value]="display.view.begin + time_suffix_begin"
                            (blur)="checkTimeChange($event)"
                            (input)="checkTimeInput($event)"
                            (keyup.enter)="jumpToField('end')"
                            (propertychange)="checkTimeInput()">
                    </td>
                </tr>

                <tr [ngClass]="{'text-muted': (isBlocked === true) || (isNeu === true)}">
                    <td class="w-50 pl-1 pr-1 align-middle text-right">Arbeitsende</td>
                    <td class="w-50 pl-1 pr-1 align-middle text-left">
                        <input type="text" id="end-{{display.date}}" name="end-{{display.date}}"
                            autocomplete="off"
                            class="form-control input"
                            [attr.disabled]="editMode ? null : 'disabled'"
                            [ngClass]="{'disabled': editMode === false, 'text-muted': (isBlocked === true) || (isNeu === true)}"
                            [value]="display.view.end + time_suffix_end"
                            (blur)="checkTimeChange($event)"
                            (input)="checkTimeInput($event)"
                            (keyup.enter)="jumpToField('break')"
                            (propertychange)="checkTimeInput()">
                    </td>
                </tr>

                <tr [ngClass]="{'text-muted': (isBlocked === true) || (isNeu === true)}">
                    <td class="w-50 pl-1 pr-1 align-middle text-right" [ngClass]="{'invalid': checkWorkTimeOk === false}">Arbeitszeit</td>
                    <td class="w-50 pl-1 pr-1 align-middle text-left">
                        <input type="text" id="work-{{display.date}}" name="work-{{display.date}}"
                            class="form-control input disabled"
                            [ngClass]="{'text-muted': (isBlocked === true) || (isNeu === true), 'invalid': checkWorkTimeOk === false}"
                            [value]="display.view.work"
                            disabled>
                    </td>
                </tr>

                <tr [ngClass]="{'text-muted': (isBlocked === true) || (isNeu === true)}">
                    <td class="w-50 l-1 pr-1 align-middle text-right" [ngClass]="{'invalid': checkBreakTimeOk === false}">Pausenzeit</td>
                    <td class="w-50 pl-1 pr-1 align-middle text-left">
                        <input type="text" id="break-{{display.date}}" name="break-{{display.date}}"
                            autocomplete="off"
                            class="form-control input"
                            [ngClass]="{'disabled': editMode === false, 'text-muted': (isBlocked === true) || (isNeu === true), 'invalid': checkBreakTimeOk === false}"
                            [value]="display.view.break"
                            [attr.disabled]="editMode ? null : 'disabled'"
                            (blur)="checkTimeChange($event)"
                            (input)="checkTimeInput($event)"
                            (keyup.enter)="jumpToField('comment')"
                            (propertychange)="checkTimeInput()">
                    </td>
                </tr>

            </table>

            <div *ngIf="isMesseprojekt === false" class="form-group">
                <label class="control-label" for="textarea" [ngClass]="{'text-muted': (isBlocked === true) || (isNeu === true), 'invalid': checkWorkTimeOk === false}">Kommentar zum Tag</label>
                <textarea id="textarea" rows="1" class="form-control comment" id="comment-{{display.date}}" name="comment-{{display.date}}"
                    maxlength="512"
                    [attr.disabled]="editMode ? null : 'disabled'"
                    [ngClass]="{'text-muted': (isBlocked === true) || (isNeu === true)}"
                    [(ngModel)]="display.view.comment">
                </textarea>
            </div>

        </form>

        <!-- DISPLAY Tagesstatus "NICHT Zeiterfassung" -->

        <div *ngIf="isSpecialDay === true" class="mt-3 mt-sm-4 pb-3 text-center">
            <p class="my-5 h1">{{toolsService.getDayStateText(display.view.state)}}</p>
        </div>

        <!-- DIE BUTTONS -->

        <div *ngIf="isBlocked === false">

            <!-- BUTTONs: Daten ändern -->

            <div *ngIf="display.view.blocked === ''">
                <div *ngIf="editMode === false">
                    <button *ngIf="display.view.state != 'START'" class="btn btn-primary btn-lg btn-block mt-3" (click)="edit()">Daten ändern</button>
                    <button *ngIf="display.view.state === 'START'" class="btn btn-primary btn-lg btn-block mt-3 blocked" disabled="disabled">Daten ändern</button>
                </div>
            </div>

            <div *ngIf="isMesseprojekt === true && editMode === false">
                <button class="btn btn-primary btn-lg btn-block mt-3" (click)="jump2todayMonth()">Stundennachweis an LZ</button>
            </div>

            <div *ngIf="display.view.blocked !== ''">
                <button class="btn btn-lg btn-block mt-3 disabled">Daten freigegeben und versendet am {{transfer_lorenz}}</button>
            </div>

            <!-- BUTTONs: Tagesstatus ändern -->

            <div *ngIf="editMode === true">
                <div *ngIf="isMesseprojekt === false">
                    <div class="text-center mt-3">
                        <button title="Zeiterfassung" class="btn tagesstatus col-1 mx-1" [ngClass]="{'btn-primary':isSpecialDay === false }" (click)="changeDayState('STOP')">Z</button>
                        <button title="Krank" class="btn tagesstatus col-1 mx-1" [ngClass]="{'btn-primary':display.view.state === 'K' }" (click)="changeDayState('K')">K</button>
                        <button title="Urlaub" class="btn tagesstatus col-1 mx-1" [ngClass]="{'btn-primary':display.view.state === 'U' }" (click)="changeDayState('U')">U</button>
                        <button title="Sonderurlaub" class="btn tagesstatus col-1 mx-1" [ngClass]="{'btn-primary':display.view.state === 'SU'}" (click)="changeDayState('SU')">SU</button>
                        <!-- <button title="Kurzarbeitergeld" class="btn tagesstatus col-1 mx-1" [ngClass]="{'btn-primary':display.view.state === 'KuG'}" (click)="changeDayState('KuG')">KuG</button> -->
                        <button title="Feiertag" class="btn tagesstatus col-1 mx-1" [ngClass]="{'btn-primary':display.view.state === 'FT'}" (click)="changeDayState('FT')">FT</button>
                        <button title="Gleitzeit" class="btn tagesstatus col-1 mx-1" [ngClass]="{'btn-primary':display.view.state === 'GZ'}" (click)="changeDayState('GZ')">GZ</button>
                    </div>
                </div>
                <div class="text-center mt-3">
                    <button class="btn btn-danger col-5" (click)="cancel()">Abbrechen</button>
                    <button class="btn btn-primary col-5 offset-2" (click)="save()">Speichern</button>
                </div>
            </div>

            <!-- BUTTONs: Zeiterfassung -->

            <div *ngIf="isMesseprojekt === false">
                <div *ngIf="(isHeute === true) && (isSpecialDay === false) && (editMode === false)">
                    <button class="btn btn-primary btn-lg btn-block mt-3" *ngIf="display.view.state !== 'START'" (click)="zeiterfassungStart()" id="start">START Zeiterfassung</button>
                    <button class="btn btn-danger btn-lg btn-block mt-3" *ngIf="display.view.state === 'START'" (click)="zeiterfassungStop()" id="stop">STOPP Zeiterfassung</button>
                </div>
            </div>

        </div>

    </div>

</div>