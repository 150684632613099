/**
 * Datentyp für die Verwaltung der Anzeigematrix
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-06-01
 * Revision: 2020-01-16
 *
 */



import { Display } from '../_models/display.model';



export class ViewMatrix {
    public row = {};
    constructor() {
        for (let i = 0; i < 3; i++) {
            this.row[i] = new ViewMatrixRow();
        }
    }
}



export class ViewMatrixRow {
    public item = {}; // Je ein Display-Objekt für die Anzeige in der Zelle
    constructor() {
        for (let i = 0; i < 3; i++) {
            this.item[i] = new Display();
        }
    }
}
