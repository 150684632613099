<ng-template #filesDialogModal let-modal>

    <app-files-viewer [file]="file"></app-files-viewer>

    <div class="modal-header">
        <strong>Wichtige Dokumente zum Projekt</strong>
    </div>

    <div class="modal-body p-0">
        <ul class="list-group list-group-flush">
            <li
                *ngFor="let file of filesList"
                class="list-group-item"
                [class.read]="isRead(file)"
                (click)="downloadOrViewFile(file)">

                {{ file.filename }}

            </li>
        </ul>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close()">Schließen</button>
    </div>

</ng-template>