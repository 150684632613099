/**
 * Komponente für die Anzeige des Dialogs zur Dateianzeige
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2020-10-30
 *
 */

import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core'
import {FileDocument} from '../_models/file-document.model'
import {FilesService, StorageService, ToolsService} from '../_services/_index'

@Component({
    selector: 'app-files-dialog',
    templateUrl: './files-dialog.component.html',
    styleUrls: ['./files-dialog.component.css'],
})
export class FilesDialogComponent implements OnInit, AfterViewInit {

    @ViewChild('filesDialogModal', {static: true}) public filesDialogModal: ElementRef

    public filesList: FileDocument[] = null // Liste der anzuzeigenden Dateien

    public file: FileDocument

    // LIFECYCLE -----------------------------------------------------------------------------------------------

    constructor(
        private filesService: FilesService,
        private storageService: StorageService,
        private toolsService: ToolsService,
    ) {
        this.toolsService.debugInfo('FilesDialogComponent', 'constructor', 'LIFECYCLE')
    }

    public ngOnInit(): void {

        // Observable für die Dateiliste abonnieren
        this.filesService.filesList.subscribe((filesList) => {
            this.filesList = filesList
        })

    }

    public ngAfterViewInit(): void {

        // Die Dateiliste aktualisieren.
        this.filesService.fetchFilesList()

        // Das Modal beim Service anmelden.
        this.filesService.registerModal('FilesDialog', this.filesDialogModal)
    }

    // METHODS -------------------------------------------------------------------------------------------------

    // Liefert die Datei zum Download (Standard) oder per Viewer (AppTitan oder mp4).
    public downloadOrViewFile(file: FileDocument): void {

        this.filesService.fetchFilesList()

        const clientType = this.storageService.get('ClientType')
        const isMp4 = file.filename.slice(-4).toLowerCase() === '.mp4'

        if (clientType === '1' || isMp4) {

            // AppTitan or mp4
            this.file = file
            this.filesService.openModal('FilesViewer')

        } else {

            // Standard
            this.filesService.downloadFile(file, 'FilesDialogComponent')

        }
    }

    // Liefert TRUE, wenn eine Datei schon mal geladen wurde, sonst FALSE.
    public isRead(file: FileDocument): boolean {
        return file.first_view
    }

}
