/**
 * Datentyp für die Anzeige der eigentlichen Arbeitszeitdaten (Tages-, Monats- und Jahressicht)
 *
 * FDit UG (haftungsbeschränkt) für Lorenz Projekte GmbH & Co. KG
 *
 *    Frank Donndorf - office@fd-it.de
 *
 * Erstellt: 2018-02-01
 * Revision: 2020-01-16
 *
*/



// Struktur für die Anzeige
export class Display {

    public date = ''; // Datum der Daten im Format "YYYY-MM-DD"; "YYYY-MM" oder "YYYY"
    public typ = ''; // Display-Typ 'tag', 'monat' oder 'jahr'
    public view: any = null; // Die eigentlichen Daten für die Anzeige

    public projectname = ' '; // Projektname (nur für die Anzeige) - Achutng ist ein "ALT+0160"-Zeichen wg. Darstellung ;-)

    public id_user = -1; // Users.ID zum Display
    public id_project = -1; // Projects.ID zum Display
}



// View: TAG -----------------------------------------------------------------------------------------------



// Struktur für die Tageswerte.
export class DisplayViewDay {

    public blocked = ''; // Tagesdaten sind gesperrt <Übertragungsdatum> oder '' (leer), wenn nicht geblockt

    public state = 'STOP'; // Status der Zeiterfassung 'START', 'STOP' oder das Kürzel für die "Spezialtage" (K/U/FT/GZ)

    public begin = ''; // Arbeitsbeginn HH:MM
    public end = ''; // Arbeitsende HH:MM
    public work = ''; // Arbeitszeit HH:MM
    public break = ''; // Pausenzeit HH:MM

    public comment = ''; // Kommentar zum Tag

    public firstStart = ''; // Erste Start-Buchung am Tag
    public lastStop = ''; // Letzte Ende-Buchung am Tag
    public secondsWork = 0; // Tägliche Arbeitszeit in Sekunden
    public secondsBreak = 0; // Tägliche Pausenzeit in Sekunden
}



// View: MONAT ---------------------------------------------------------------------------------------------



// Strukturen für die Monatswerte.
export class DisplayViewMonth {
    public transfer_user = ''; // Letzter Abruf der Monatsübersicht vom User oder leer, wenn noch nicht abgerufen
    public transfer_lorenz = ''; // Letzter Transfer der Monatsübersicht zu Lorenz (oder hinterlegte E-Mai-Adresse) oder leer, wenn noch nicht übertragen
    public checkOk = ''; // Flag für "Monat OK => versendbar" 0 = nein; 1=ja
    public checksum = ''; // MD5 Prüfsumme für den Download
    public days: DisplayViewMonthDetails[] = null;
}

export class DisplayViewMonthDetails {
    public date = '';
    public day = '';
    public begin = '';
    public end = '';
    public work = '';
    public break = '';
    public comment = '';
    public checkOk = true;
}


// View: MONAT ---------------------------------------------------------------------------------------------



// Strukturen für die Jahreswerte.
export class DisplayViewYear {
    public months: DisplayViewYearDetails[] = null;
    public sums: DisplayViewYearDetails = null;
}

export class DisplayViewYearDetails {
    public date = '';
    public month = '';
    public work = '';
    public krank = '';
    public urlaub = '';
    public sonderurlaub = '';
    public feiertag = '';
    public gleitzeit = '';
}
