<div *ngIf="waitingForServer === true" class="fd_loading_spinner_stage_background"></div>
<div *ngIf="waitingForServer === true" class="fd_loading_spinner_stage">
	<div class="fd_loading_spinner"></div>
</div>

<app-functions title="Passwort ändern"></app-functions>

<div id="password" class="p-4">

	<div *ngIf="success == false">
		<form class="mt-3 mt-sm-4">

			<div class="form-group">
				<label for="passwordOne">Aktuelles Passwort</label>
				<input #passwordOneElement type="password" id="passwordOne" name="passwordOne" autocomplete="new-password" class="form-control" [(ngModel)]="passwordOne">
			</div>

			<div class="form-group">
				<label for="passwordTwo">Neues Passwort</label>
				<input #passwordTwoElement type="password" id="passwordTwo" name="passwordTwo" autocomplete="new-password" class="form-control" [(ngModel)]="passwordTwo">
			</div>

			<div class="form-group">
				<label for="passwordThree">Neues Passwort (Wiederholung)</label>
				<input #passwordThreeElement type="password" id="passwordThree" name="passwordThree" autocomplete="new-password" class="form-control" [(ngModel)]="passwordThree">
			</div>

			<div *ngIf="alert != ''" class="mt-5 alert alert-warning">
				{{alert}}
			</div>

			<div *ngIf="success == false" class="mt-5">
				<button class="btn btn-primary btn-block btn-lg" (click)="passwordChange()">Passwort ändern</button>
			</div>

		</form>
	</div>

	<div *ngIf="success === true" class="mt-5 alert alert-success">
		Passwort wurde geändert
	</div>

	<div class="mt-5">
		<button class="btn btn-primary btn-block btn-lg" (click)="close()">Schließen</button>
	</div>

</div>